import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from '@angular/forms';
import * as html2canvas from 'html2canvas';
import print from 'print-js';
import { BaseComponent } from '../shared/base/base.component';
import { MatDialog, MatSnackBar } from '@angular/material';
import { MessageComponent } from '../shared/message/message.component';
import { LoginService } from 'src/app/services/login.service';
import { Router } from '@angular/router';
import { UserData } from '../login/login.component';
import { isUndefined } from 'util';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent extends BaseComponent implements OnInit {

  year = new Date().getFullYear() - 1;
  year1 = new Date().getFullYear() - 2; 
  showNoResultMessage = false;
  loading = false;
  formGroup: FormGroup;
  userdata
  anio: Array<{ clave: any, valor: any }> = [];

  constructor(private _formBuilder: FormBuilder, private dialogs: MatDialog,
    private snackBars: MatSnackBar, private sessionInfo: LoginService, private router: Router) {
    super(dialogs, snackBars);
    /*if (sessionStorage.length == 0) {
      this.router.navigate(['/login'])
    } else {
      this.userdata = JSON.parse(sessionStorage.userInfo)
    }*/
    if (isUndefined(this.sessionInfo.readCookie("1524"))) {
      this.router.navigate(['/login'])
    } else {
      console.log(this.sessionInfo.readCookie("1524"));
      this.userdata = this.sessionInfo.readCookie("1524")['user']
    }
  }

  ngOnInit() {
    this.formGroup = this._formBuilder.group({
      year: ['', Validators.required],
    });
    this.anio.push({ clave: this.year1, valor: this.year1 })
    this.anio.push({ clave: this.year, valor: this.year })
  }

  getStatement() {
    this.loading = true;
    const DATA = { S011: this.userdata['CNNOSS'] ,
 /*       const DATA = { S011: '1018404187' , */
     ANIO: this.formGroup.get('year').value}
    this.sessionInfo.getReport(DATA).subscribe(data => {
      console.log(data);
      this.loading = false;      
      data.forEach(element => {
        element['year'] = this.formGroup.get('year').value;
      });
      this.openDialog(MessageComponent, data, 'dialog-transaction-ok');
    }, error => {
      alert(this.sessionInfo.error(error));
    });
    
  }

}
