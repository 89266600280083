import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { LoginService } from '../../services/login.service';
import { MatDialogRef } from '@angular/material';
import { trigger, state, style, transition, animate, query, group } from '@angular/animations';
import { ConverterService } from 'src/app/services/converter.service';

@Component({
  selector: 'app-userrecovery',
  templateUrl: './userrecovery.component.html',
  styleUrls: ['./userrecovery.component.css'],
  animations: [
    trigger('slidein', [
      state('in', style({ opacity: 0, transform: 'translateX(0)' })),
      transition('void => *', [
        style({ opacity: 1, transform: 'translateX(100%)', }),
        animate(500)
      ])
    ]),
    trigger('slideDown', [
      state('in', style({ opacity: 0, transform: 'translateY(100%)' })),
      transition('void => *', [
        style({ opacity: 1, transform: 'translateY(0)', }),
        animate(500)
      ])
    ]),
    trigger('fadeIn', [state('in', style({ opacity: 1 })),
    transition('void=> *', [style({ opacity: 0 }),
    animate(500)])])
  ]
})
export class UserrecoveryComponent extends ConverterService implements OnInit {
  docType = [
    { value: '0', viewValue: 'NUIP', },
    { value: '1', viewValue: 'Cedula de Ciudadanía' },
    { value: '2', viewValue: 'Cedula de Extranjería' },
    { value: '3', viewValue: 'NIT' },
    { value: '4', viewValue: 'Tarjeta de Identidad' },
    { value: '5', viewValue: 'Pasaporte' },
    { value: '6', viewValue: 'Identificación Extranjera' },
    { value: '9', viewValue: 'Registro Civil' },
  ];
  serverMsg:string
  firstFormGroup: FormGroup;
  userRecoverres: string
  loading: boolean = false
  result: boolean = false
  badPass:boolean
  notUser: boolean
 
  constructor(private routes: Router, private _formBuilder: FormBuilder, private sendData: LoginService, 
    public dialogref: MatDialogRef<UserrecoveryComponent>) {
      super()
     }

  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
      document_type: ['', Validators.required],
      identification: ['', Validators.required],
      password: ['', Validators.required]
    });

    
  }



  recoverUser() {
    if (this.firstFormGroup.valid) {
      this.loading = true
      let data = {
        "CNCDTI": this.firstFormGroup.value['document_type'],
        "CNNOSS": this.firstFormGroup.value['identification'],
        "S096": this.firstFormGroup.value['password']
      }
      // console.log('data para recuperar usuario', data)
      this.sendData.recoveryUser(data).subscribe(res => {
        // console.log('respuesta recuperar usuario', JSON.parse(this.decryptAr(res['data'])))
        this.loading = false
        this.result = true
        let dE = JSON.parse(this.decryptAr(res['data']));
        this.userRecoverres = dE.S095
      },
        (error) => {
          this.loading = false
          this.serverMsg = "Ha ocurrido un error. Revice los datos suministrados";
          this.notUser = true
          setTimeout(() => {
            this.notUser = false;
            this.firstFormGroup.reset();
          }, 2000)

        })
    }
  }
  goBack() {
   this.dialogref.close()
  }

}
