import { Component, OnInit, ViewChild, QueryList, ViewChildren } from '@angular/core';
import { MatGridList, MatGridTile } from '@angular/material';
import { CreditselectorComponent } from '../creditselector/creditselector.component';
import { trigger, state, style, transition, animate, query, group } from '@angular/animations';
import { Router } from '@angular/router';
import { element } from 'protractor';
import { NgcFloatButtonComponent } from 'ngc-float-button';
import { HostListener } from '@angular/core';
import { InfotransferService } from '../../services/infotransfer.service';
import { LoginService } from 'src/app/services/login.service';
import { isUndefined } from 'util';


@Component({
  selector: 'app-credit-details',
  templateUrl: './credit-details.component.html',
  styleUrls: ['./credit-details.component.css'],
  animations: [
    trigger('slidein', [
      state('in', style({ opacity: 0, transform: 'translateX(0)' })),
      transition('void => *', [
        style({ opacity: 1, transform: 'translateX(100%)', }),
        animate(500)
      ])
    ]),
    trigger('slideDown', [
      state('in', style({ opacity: 0, transform: 'translateY(100%)' })),
      transition('void => *', [
        style({ opacity: 1, transform: 'translateY(0)', }),
        animate(500)
      ])
    ]),
    trigger('fadeIn', [state('in', style({ opacity: 1 })),
    transition('void=> *', [style({ opacity: 0 }),
    animate(500)])])
  ]
})
export class CreditDetailsComponent implements OnInit {
  @ViewChild('cardtiles') cardlist
  @ViewChild('scrollbutton')scrollview:NgcFloatButtonComponent


  cards = [
    { title: 'Resumen', cols: 2, rows: 1, component: 'resumen', id: 'summary' },
    { title: 'Detalles de su Crédito', cols: 2, rows: 1, component: 'selctordecredito', id: 'details' },
    { title: 'Construcción de Saldos', cols: 2, rows: 1, component: 'construccion', id: 'construct' },
    { title: 'Plan de pagos', cols: 2, rows: 1, component: 'planpagos', id: 'payplan' },


  ];
  colNumber = 2

  summary
  details
  construct
  payplan
  showNavButton
  position

  constructor(private router: Router, private tranferInfo: InfotransferService, private _loginService: LoginService) {
    /*if (sessionStorage.length == 0) {
      this.router.navigate(['/login'])
    }*/
    if (isUndefined(this._loginService.readCookie("1524"))) {
      this.router.navigate(['/login'])
    } 
  }

  ngOnInit() {
this.tranferInfo.goTo.subscribe(resp=>{
switch (resp) {
  case 1:
  this.summary.scrollIntoView()
    break;
    case 2:
    this.details.scrollIntoView()
    break;
    case 3:
    this.construct.scrollIntoView()
    break;
    case 4:
    this.payplan.scrollIntoView()
    break;

  default:
    break;
}
}
)


  }

  ngAfterViewInit() {
    this.summary = document.getElementById('summary')
    this.details = document.getElementById('details')
    this.construct = document.getElementById('construct')
    this.payplan = document.getElementById('payplan')
  }
  onResize(a) {
    let col = this.cardlist.toArray()
    if (a.target.innerWidth <= 400) {
      col[0].colspan = 1
    } else {
      col[0].colspan = 2
    }
    this.colNumber = (a.target.innerWidth <= 400) ? 1 : 2

  }

  onScroll(e){
    this.position=e.clientY
    // console.log(window);
  }

  goDetails(a) {
    // console.log(a);
    
    this.details.scrollIntoView()
  }

  goSummary(a) {
    // console.log(this.scrollview);
    this.summary.scrollIntoView()
  }

  goConstruct(a) {
    // console.log(this.scrollview)
    this.construct.scrollIntoView()
  }

  goPayplan(a) {
    // console.log(this.scrollview)
    this.payplan.scrollIntoView()
  }

  showNav(){
this.showNavButton=true
setTimeout(() => {
  this.showNavButton=false
}, 2000);
  }


}
