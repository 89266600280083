import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import * as crypto from 'crypto-js';
import { map, switchMap } from 'rxjs/operators';
import { isUndefined } from 'util';

var httpOptions = {
  headers: new HttpHeaders({
    Authorization: 'Basic ' + btoa("bankvision:bankvision")
  })
};

@Injectable({
  providedIn: 'root'
})
export abstract class BaseService {
  HttpUrl = environment.HttpUrl;
  refreshUrl = environment.refreshUrl;
  refreshKey = environment['refreshKey'] ? environment["refreshKey"] : '9163';
  tokenKey = environment['tokenKey'] ? environment["tokenKey"] : '1524';
  HttpUrlUser: string = environment.HttpUrl + 'user-suite-service';
  HttpUrlAuth: string = environment.HttpUrl + 'auth-service';
  HttpUrlAes: string =  environment.HttpUrl+ 'aes-service';
  optionsToUse: Options;
  optionsDefault: Options = {
    url: 0,
    header: true,
    encrypt: true,
    token: ''
  }

  errosMsg = {}

  constructor(private http: HttpClient, private _cookieService: CookieService) { }

  //Métodos para manejar almacenamiento

  public setLocalStorage(key: string, data) {
    this.setStorage(0, key, data);
  }

  public readLocalStorage(key: string) {
    return this.readStorage(0, key);
  }

  public deleteLocalStorage(key: string) {
    this.deleteStorage(0, key);
  }

  public setSesionStorage(key: string, data) {
    this.setStorage(1, key, data);
  }

  public readSesionStorage(key: string) {
    return this.readStorage(1, key);
  }

  public deleteSesionStorage(key: string) {
    this.deleteStorage(1, key);
  }

  public setCookie(key: string, data, expires = 0) {
    this.setStorage(2, key, data, expires);
  }

  public readCookie(key: string) {
    return this.readStorage(2, key);
  }

  public deleteCookie(key: string) {
    this.deleteStorage(2, key);
  }

  private storageKey(key: string) {
    return key === "" ? this.tokenKey : key;
  }

  private setStorage(location: number, key: string, value, expires = 0) {
    let data = JSON.stringify(value);
    let encryptedData:any = crypto.AES.encrypt(data.toString(), "9e7j0c7n2o1k0e3y6j0a6");
    key = this.storageKey(key);
    switch (location) {
      case 0:
        localStorage.removeItem(key);
        localStorage.setItem(key, encryptedData);
        break;
      case 1:
        sessionStorage.removeItem(key);
        sessionStorage.setItem(key, encryptedData);
        break;
      case 2:
        if (key == this.tokenKey) {
          let date = new Date();
          let time = date.getTime();
          time += (value['token']['expires_in']*1000);
          date.setTime(time);
          this._cookieService.set(this.refreshKey, '', date, '/','',true,'None');
        }
        this._cookieService.delete(key, '/');
        this._cookieService.set(
          key,
          encryptedData,
          expires,
          '/',
          "",
          true, 
          'None'
          );
        break;
      default:
        break;
    }

  }

  private readStorage(location: number, key: string) {
    key = this.storageKey(key);
    let encryptedData;
    switch (location) {
      case 0:
        encryptedData = localStorage.getItem(key);
        break;
      case 1:
        encryptedData = sessionStorage.getItem(key);
        break;
      case 2:
        encryptedData = this._cookieService.get(key);
        break;
      default:
        break;
    }
    if (encryptedData) {
      let decryptedData = crypto.AES.decrypt(encryptedData, "9e7j0c7n2o1k0e3y6j0a6");
      return JSON.parse(decryptedData.toString(crypto.enc.Utf8));
    }
    return null;
  }

  private deleteStorage(location: number, key: string) {
    key = this.storageKey(key);
    switch (location) {
      case 0:
        localStorage.removeItem(key);
        break;
      case 1:
        sessionStorage.removeItem(key);
        break;
      case 2:
        if(key == this.tokenKey) {
          this._cookieService.delete(this.refreshKey, '/');
        }
        this._cookieService.delete(key, '/');
        break;
      default:
        break;
    }
  }

  //Método para poner el token en el header
  private setToken(token: string) {
    const data = this.readCookie(this.storageKey(""));
    const TOKEN = token ? token : data['token']['access_token'];
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${TOKEN}`
      })
    };
    return httpOptions;
  }

  //Métodos para manejar las opciones
  protected setOptions(url: number, headers: boolean = true, encrypt: boolean = true, token: string = '') {
    var OPTIONS: Options = {
      url: url,
      header: headers,
      encrypt: encrypt,
      token: token
    }
    return OPTIONS;
  }

  private setData(options: Options, data?) {
    this.optionsToUse = options ? options : this.optionsDefault;
    const httpurl = this.HttpUrl[this.optionsToUse.url];
    const httpOptions = this.optionsToUse.header ? this.setToken(this.optionsToUse.token) : {};
    const httpData = data ? this.returnDataEncrypt(this.optionsToUse.encrypt, data) : {};
    return { httpurl: httpurl, httpOptions: httpOptions, httpData: httpData };
  }

  private returnDataDecrypt(encrypt: boolean, data) {
    return encrypt && data ? JSON.parse(this.decrypt(data['data'])) : data;
  }

  private returnDataEncrypt(encrypt: boolean, data) {
    return encrypt ? this.encrypt(JSON.stringify(data)) : data;
  }

  //Métodos REST
  protected get(url: string, options?: Options) {
    return this.sendRequest('getValidate', url, {}, options);
  }

  protected post(url: string, data: any, options?: Options) {
    return this.sendRequest('postValidate', url, data, options);
  }

  protected put(url: string, data: any, options?: Options) {
    return this.sendRequest('putValidate', url, data, options);
  }

  protected delete(url: string, options?: Options) {
    return this.sendRequest('deleteValidate', url, {}, options);
  }

  protected downloadFile(url: string, data: any, options?: Options) {
    return this.sendRequest('downloadFileValidate', url, data, options);
  }

  //Métodos REST validados
  private getValidate(url, requestData) {
    return this.http.get(requestData['httpurl'] + url, requestData['httpOptions']).pipe(map(answer => {
      return this.returnDataDecrypt(this.optionsToUse.encrypt, answer);
    }));
  }

  private postValidate(url, requestData) {
    return this.http.post(requestData['httpurl'] + url, requestData['httpData'], requestData['httpOptions']).pipe(map(answer => {
      return this.returnDataDecrypt(this.optionsToUse.encrypt, answer);
    }));
  }

  private putValidate(url, requestData) {
    return this.http.put(requestData['httpurl'] + url, requestData['httpData'], requestData['httpOptions']).pipe(map(answer => {
      return this.returnDataDecrypt(this.optionsToUse.encrypt, answer);
    }));;
  }

  private deleteValidate(url, requestData) {
    return this.http.delete(requestData['httpurl'] + url, requestData['httpOptions']).pipe(map(answer => {
      return this.returnDataDecrypt(this.optionsToUse.encrypt, answer);
    }));
  }

  private downloadFileValidate(url, requestData) {
    requestData['httpOptions']['headers'].append('responseType', 'blob');
    return this.http.post(requestData['httpurl'] + url, requestData['httpData'], { headers: requestData['httpOptions']['headers'], responseType: 'blob' as 'json' }).pipe(map(answer => {
      return answer;
    }));
  }

  private sendRequest(type: string, url: string, data: any, options?: Options) {
    let requestData = this.setData(options, data);
    if(this.optionsToUse.header && !this.optionsToUse.token && !this._cookieService.check(this.refreshKey)) {
      const DATA = this.readCookie(this.storageKey(""));
      const REFRESH = { refresh: DATA['token']['refresh_token'] };
      return this.http.post(this.refreshUrl, REFRESH).pipe(switchMap(data => {
        DATA['token'] = data['token'];
        this.setCookie('', DATA);
        requestData['httpOptions'] = this.setToken('');        
        return this[type](url, requestData);
      }))
    }
    return this[type](url, requestData);
  }

  private encrypt(value) {
    const data = this.readCookie(this.storageKey(""));
    var key = crypto.enc.Hex.parse(data['key']);
    var iv = crypto.enc.Hex.parse('0000000000000000');
    var encrypted = crypto.AES.encrypt(crypto.enc.Utf8.parse(value.toString()), key,
      {
        iv: iv,
        mode: crypto.mode.CBC,
        padding: crypto.pad.ZeroPadding
      });
    const DATA = { data: encrypted.toString() };
    return DATA;
  }

  private decrypt(value) {
    const data = this.readCookie(this.storageKey(""));
    var key = crypto.enc.Hex.parse(data['key']);
    var iv = crypto.enc.Hex.parse('0000000000000000');
    var decrypted = crypto.AES.decrypt(value, key, {
      iv: iv,
      mode: crypto.mode.CBC,
      padding: crypto.pad.ZeroPadding
    });
    return decrypted.toString(crypto.enc.Latin1);
  }

  public error(value) {
    const errors = JSON.parse(this.decrypt(value.error['data']));
    return isUndefined(this.errosMsg[errors['message']]) ? errors['message'] : this.errosMsg[errors['message']];
  }

  encrypt1(X)  {
    var key = crypto.enc.Hex.parse('90325F38F29BA5B60C2AA637DB78281C');
   var iv = crypto.enc.Hex.parse('0000000000000000');
   var encrypted = crypto.AES.encrypt(crypto.enc.Utf8.parse(X.toString()), key,
   {
     iv: iv,
     mode: crypto.mode.CBC,
     padding: crypto.pad.ZeroPadding
   });
   const DATA = { data: encrypted.toString() };
   return  DATA;
 }

 async getToken(username: string, _callback, password: string = 'bankvision', grant_type: string = 'password') {
  var result = null;
  let data = {"compania":username};
  this.http.post<any>(this.HttpUrlUser + '/token/getTokenByCompany', JSON.parse(JSON.stringify(data)), httpOptions).subscribe(async x => {
    if("valid" in x){
      if (x["valid"]){
        result = x["access_token"];
      } else {
        result = await this.getNewToken(username,password,grant_type, true);
      }
    }else {
      console.log('No se encontró token activo para esta compañía en la base de datos');
      console.log('Se esta generando un token nuevo para la compañía ' + username);
      result = await this.getNewToken(username,password,grant_type, false);
    }
  }, async error => {
    console.log('Error en la comunicación con la base de datos: ' + error.error['descripcion']);
  }).add(() => {
    _callback(result);
    return result;
  });
}
async updateToken(compania: string, data: JSON, descripcion: string = ''){
  let token = {
    "compania":compania,
    "active":1,
    "data":JSON.stringify(data),
    "description":descripcion
  };
  this.http.put<any>(this.HttpUrlUser + '/token/updateActiveTokenByCompany', JSON.parse(JSON.stringify(token)), httpOptions).subscribe(x => {
    console.log('Se ha actualizando el token en la base de datos');
  }, error => {
    console.log('No se puede actualizar el token en la base de datos: ' + error.error['descripcion']);
  });
  
}
decryptToken(data: JSON, _callback) {
  var result = null;
  this.http.post<any>(this.HttpUrlAes + '/decrypt', data, httpOptions).subscribe(x => {
    result = x;
  }, error => {
    console.log('Error en la decodificación de la información: ' + error.error['descripcion']);
  }).add(() => {
    _callback(result);
    return result;
  });
}

getNewToken(username: string, password: string = 'bankvision', grant_type: string = 'password', exists: boolean = false) {
  var result = null;
  const formData = new FormData();
  formData.append('username', username);
  formData.append('password', password);
  formData.append('grant_type', grant_type);
  this.http.post<any>(this.HttpUrlAuth + '/oauth/token', formData, httpOptions).subscribe(async x => {
    //let access_token = await this.decrypt(x);
    await this.decryptToken(x, async answ => {
      let access_token = answ[0]["access_token"];
      //this.decrypt(x, () => {});
      if (access_token === null) {
        console.log('Error en la decodificación del token');
      } else {
        if (exists) {
          await this.updateToken(username, x);
        } else {
          await this.storeNewToken(username, x);
        }
      }
      result = access_token;
    });
  }, error => {
    console.log('Error en la generación del Token: ' + error.error['descripcion']);
  }).add(() => {
    return result;
  });
}

async storeNewToken(compania: string, data: JSON, descripcion: string = ''){

  let token = {
    "compania":compania,
    "active":1,
    "data":JSON.stringify(data),
    "description":descripcion
  };
  this.http.post<any>(this.HttpUrlUser + '/token/setToken', JSON.parse(JSON.stringify(token)), httpOptions).subscribe(x => {
  //this.http.post<any>(this.HttpUrlUser + '/token/setToken', token, httpOptions).subscribe(x => {
    console.log('Se ha guardado el nuevo token en la base de datos');
  }, error => {
    console.log('No se puede almacenar el nuevo token en la base de datos: ' + error.error['descripcion']);
  });
  console.log('Se esta almacenando un nuevo token');
}

}

interface Options {
  url: number;
  encrypt: boolean;
  header: boolean;
  token: string;
}