import { Component } from "@angular/core";
import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState
} from "@angular/cdk/layout";
import {
  trigger,
  state,
  style,
  transition,
  animate,
  query,
  group
} from "@angular/animations";
import { Observable } from "rxjs";
import { IpService } from "../../services/ip.service";
import { Router } from "@angular/router";
import { LoginService } from "../../services/login.service";
import { MatDialogRef, MatDialog } from "@angular/material";
import { PasswordrecoveryComponent } from "../passwordrecovery/passwordrecovery.component";
import { InfotransferService } from "../../services/infotransfer.service";
import { error, isUndefined } from "util";
import { CurrencyPipe } from "@angular/common";

@Component({
  selector: "navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"],
  animations: [
    trigger("slidein", [
      state("in", style({ opacity: 0, transform: "translateX(0)" })),
      transition("void => *", [
        style({ opacity: 1, transform: "translateX(100%)" }),
        animate(500)
      ])
    ]),
    trigger("fadeIn", [
      state("in", style({ opacity: 1 })),
      transition("void=> *", [style({ opacity: 0 }), animate(1000)])
    ])
  ]
})
export class NavbarComponent {
  actualip = new getIp();
  actualTime;
  userInfo;
  showInfo: boolean = false;
  changepass: MatDialogRef<PasswordrecoveryComponent>;
  infoReady: boolean;
  button;
  isHandset: Observable<BreakpointState> = this.breakpointObserver.observe(
    Breakpoints.Handset
  );

  constructor(
    private _dataUser: LoginService,
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    private _ipService: IpService,
    private routes: Router,
    private currency: CurrencyPipe,
    private _loginService: LoginService,
    private tranferInfo: InfotransferService
  ) {
    if (isUndefined(this._loginService.readCookie("1524"))) {
      this.routes.navigate(["/login"]);
    } else {
      //this.userInfo = JSON.parse(sessionStorage.userInfo)
      console.log(this._loginService.readCookie("1524"));
      this.userInfo = this._loginService.readCookie("1524")["user"];
    }
    this.button = false;
  }

  ngOnInit() {
    this.infoReady = true;
    this.routes.navigate(["/details/welcome"]);
    this.showInfo = false;
    this._dataUser.summary({ S075: this.userInfo["CNNAMK"] }).subscribe(
      (data: any) => {
        console.log(data);
        this.infoReady = false;
        let credits = [];
        let insertCredits = {};
        for (let dat of data) {
          dat["LBYATO"] = dat["LBYAPR"] + dat["LBYAIN"] + dat["LBYALC"];
          if (isUndefined(insertCredits[dat["LMNOAC"]])) {
            insertCredits[dat["LMNOAC"]] = [];
            insertCredits[dat["LMNOAC"]].push(dat);
            credits.push(dat);
          } else {
            insertCredits[dat["LMNOAC"]].push(dat);
          }
          dat["LMRTNM"] = `${dat["LMRTNM"] * 100}%`;
          const keys = Object.keys(dat);
          for (let key of keys) {
            dat[key] = this.setFormat(dat[key], key);
          }
        }
        //this._dataUser.planData = insertCredits;
        this._dataUser.creditsData = credits;
        //console.log(data);
      },
      error => {
        //console.log(this._dataUser.error(error));
      }
    );
    /*
    this._dataUser.getSumary().subscribe((resp: any) => {
      this.infoReady = false
      console.log(resp)

    })
    */

    this._loginService
      .bills({ S075: this.userInfo["CNNAMK"] })
      .subscribe(data => {
        console.log(data);
        let insertCredits = {};
        for (let dat of data) {
          dat["LBYATO"] = dat["LBYAPR"] + dat["LBYAIN"] + dat["LBYALC"];
          if (isUndefined(insertCredits[dat["LMNOAC"]])) {
            insertCredits[dat["LMNOAC"]] = [];
            insertCredits[dat["LMNOAC"]].push(dat);
          } else {
            insertCredits[dat["LMNOAC"]].push(dat);
          }
          dat["LMRTNM"] = `${dat["LMRTNM"] * 100}%`;
          const keys = Object.keys(dat);
          for (let key of keys) {
            dat[key] = this.setFormat(dat[key], key);
          }
        }
        this._dataUser.planData = insertCredits;
      });

    this._ipService.getIp().subscribe(res => {
      this.actualip = res;
    });
    this.userTime();
  }

  setFormat(value, key) {
    const format = this._dataUser.formats[key];
    if (format == 1) {
      return this.formatCurrency(value);
    } else if (format == 2) {
      return this.formatDate(value);
    }
    return value;
  }

  formatDate(date: any): string {
    let year = date.toString().slice(0, 4);
    let month = date.toString().slice(4, 6);
    let day = date.toString().slice(6, 8);
    return day + "/" + month + "/" + year;
  }

  formatCurrency(value) {
    return this.currency.transform(value, "USD", "$", "1.2-2");
  }

  userTime() {
    setInterval(() => {
      this.actualTime = new Date();
    }, 1000);
  }

  goDetail(a) {
    this.tranferInfo.goToEle(2);
  }

  goSummary(a) {
    this.tranferInfo.goToEle(1);
  }

  goConstruct(a) {
    this.tranferInfo.goToEle(3);
  }

  goPayplan(a) {
    this.tranferInfo.goToEle(4);
  }

  goDetails() {
    this.showInfo = true;
    this.button = true;
    this.routes.navigate(["/details/creditdetails"]);
  }
  goMovements() {
    this.button = false;
    this.showInfo = true;
    this.routes.navigate(["/details/movements"]);
  }

  goReports() {
    this.button = false;
    this.routes.navigate(["/details/reports"]);
  }

  changePas() {
    this.changepass = this.dialog.open(PasswordrecoveryComponent, {
      width: "500px"
    });
    this.changepass.componentInstance.ChangeRecover = "Cambiar Contraseña";
  }

  goIni() {
    this.routes.navigate([""]);
    this._loginService.deleteCookie("1524");
    this._loginService.deleteCookie("9163");
    sessionStorage.clear();
  }
}
export class getIp {
  ip: string;
  about: string;
}
