import { isUndefined, isString } from 'util';
import { MatDialogConfig, MatDialog, MatSnackBar } from '@angular/material';

export abstract class BaseComponent {
  deleteMessage = 'Eliminando';
  editMessage = 'Editando';
  createMessage = 'Creando';
  loadingMessage = 'Cargando';
  loginMessage = 'Iniciando Sesión';

  snackMessage = 'Mensaje del sistema';
  snackAction = 'Action';
  snackTime = 2000;

  userData;

  errorMessage = ``;

  constructor(private dialog?: MatDialog, private snackBar?: MatSnackBar) {
    if (!isUndefined(sessionStorage['user'])) {
      this.userData = JSON.parse(sessionStorage['user']);
    }
  }

  getErrorMessage(fieldFormGroup) {
    const ERROR = fieldFormGroup.errors;
    let message = '';
    if (!isUndefined(ERROR['required'])) {
      message = 'Debe ingresar este campo';
    } else if (
      !isUndefined(ERROR['minlength']) ||
      !isUndefined(ERROR['maxlength']) ||
      !isUndefined(ERROR['pattern'])
    ) {
      message = 'Número inválido';
    }
    return message;
  }

  afterCloseDialog(data) {}

  action(event) {}

  openDialog(component, dataDialog?, classDialog?) {
    const dialogConfig = new MatDialogConfig();

    if (!isUndefined(dataDialog['close'])) {
      dialogConfig.disableClose = dataDialog['close'];
    } else {
      dialogConfig.disableClose = true;
    }

    dialogConfig.autoFocus = true;
    dialogConfig.data = dataDialog;

    if (classDialog) {
      dialogConfig.panelClass = classDialog;
    }

    const dialogRef = this.dialog.open(component, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.afterCloseDialog(data);
      }
    });
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  openSnackBar() {
    this.snackBar.open(this.snackMessage, this.snackAction, {
      duration: this.snackTime
    });
  }

  createErrorMessage(message) {
    const errorMessage = `
    <div class="row">
      <div class="col text-center" style="font-size: 4.2rem; color: red;">
        <i class="fas fa-times"></i>
      </div>
    </div>
    <div class="row">
      <div class="col text-center" style="font-size: 1.4rem;">
        <p>${message}</p>
      </div>
    </div>`;
    return errorMessage;
  }

  createDoneMessage(message) {
    const doneMessage = `
    <div class="row">
      <div class="col text-center" style="font-size: 4.2rem; color: green;">
        <i class="fas fa-check"></i>
      </div>
    </div>
    <div class="row">
      <div class="col text-center" style="font-size: 1.4rem;">
        <p>${message}</p>
      </div>
    </div>`;
    console.log(doneMessage);
    return doneMessage;
  }
}
