import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InfotransferService {
  public show = new Subject<any>()
  public goTo=new Subject<any>()

  constructor() { }

showCreditInfo(a){
  this.show.next(a)
}
goToEle(a){
  this.goTo.next(a)
}
}
