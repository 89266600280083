import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource } from '../../../../node_modules/@angular/material';


@Component({
  selector: 'app-movements-details',
  templateUrl: './movements-details.component.html',
  styleUrls: ['./movements-details.component.css']
})
export class MovementsDetailsComponent implements OnInit {
  dataSource
  displayInfo = ['LHNOAC', 'LHNOCN', 'LHDTEF', 'LFDSTC', 'LHAMTR']
  // displayInfo = ['credit_number', 'consecutive_number', 'date', 'description', 'amount_loan']
  displayedColumns = ['Número de Crédito', 'Consecutivo', 'Fecha', 'Desc.', 'Monto']  
  datos
  constructor(public dialogRef: MatDialogRef<MovementsDetailsComponent>,
    @Inject(MAT_DIALOG_DATA)  data) { 
      this.datos = data
    }

  ngOnInit() {
    this.dataSource= new MatTableDataSource()
    this.dataSource.data = this.datos
  }

}
