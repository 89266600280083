import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { trigger, state, style, transition, animate, query, group } from '@angular/animations';


@Component({
  selector: 'app-balancecontruct',
  templateUrl: './balancecontruct.component.html',
  styleUrls: ['./balancecontruct.component.css'],
  animations: [
    trigger('slidein', [
      state('in', style({ opacity: 0, transform: 'translateX(0)' })),
      transition('void => *', [
        style({ opacity: 1, transform: 'translateX(100%)', }),
        animate(500)
      ])
    ]),
    trigger('slideOut', [
      state('in', style({ transform: 'translateX(0)' })),
      transition('* => void', [
        animate(500, style({ transform: 'translateX(100%)' }))
      ])
    ]),
    trigger('fadeIn', [state('in', style({ opacity: 1 })),
    transition('void=> *', [style({ opacity: 0 }),
    animate(1000)])])
  ]
})
export class BalancecontructComponent implements OnInit {
  credits = []
  creditTableInfo: object
  showTable: boolean
  dataSource=[]
  
  displayInfo = ['LMAMCB', 'CAUSED_INTEREST', 'DEBT_INTEREST', 'TOTAL_DEBT', 'CAPITAL', 'CURRENT_INTEREST', 'DEBT_INTEREST_C', 'INSURANCE_DEBTORS', 'TOTAL_CREDIT']
  displayedColumns = ['Saldo Capital', 'Interes Causado', 'Interes de Mora', 'Total deuda', 'Total Abono Capital', 'Interes Corriente C', 'Interes de Mora C', 'Seguros deudores', 'Total Abonos'];
 
  constructor(private _userInfo: LoginService) { }


  ngOnInit() {
    this.showTable = false
    if (typeof this._userInfo.creditsData != "undefined") {
      if (this._userInfo.creditsData.length > 0) {
        this.credits=this._userInfo.creditsData
      } 
    }
  }


  selectCredit() {
    this.dataSource=[]
    this.showTable = true
    this.dataSource.push(this.creditTableInfo)
  }
}
