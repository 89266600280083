import { Component, OnInit, ViewChild } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { MatTable, MatTableDataSource } from '@angular/material';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.css']
})
export class SummaryComponent implements OnInit {

  constructor(private _dataUser: LoginService) { }

dataSource=[]
  displayInfo=['XFDESC','LMNOAC','LKDSST','LMAMPO', 'LMAMTP', 'LMAME4']
  displayedColumns = ['Tipo de Crédito', 'Número de Crédito', 'Estado', 'Saldo Total', 'Saldo en Mora', 'Valor Cuota Anticipada'];

  ngOnInit() {   
    //console.log(this._dataUser.creditsData) 
    if(typeof this._dataUser.creditsData != "undefined" ){
      if(this._dataUser.creditsData.length>0){
        this.dataSource=this._dataUser.creditsData
      }
  }
    
     
  }

}
