// Modulos
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core';
import { LayoutModule } from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from './shared/material.module';
import {NgcFloatButtonModule} from 'ngc-float-button';

//Componentes
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { UserrecoveryComponent } from './components/userrecovery/userrecovery.component';
import { RegistuserComponent } from './components/registuser/registuser.component';
import { PasswordrecoveryComponent } from './components/passwordrecovery/passwordrecovery.component';
import { PasswordresetComponent } from './components/passwordreset/passwordreset.component';
import { CreditDetailsComponent } from './components/credit-details/credit-details.component';

//Servicios
import { LoginService } from "./services/login.service";
import { IpService } from './services/ip.service';
import { InfotransferService } from './services/infotransfer.service';

//Extras
import { APP_ROUTING } from './app.routes';
import 'hammerjs/hammer';
import { SummaryComponent } from './components/summary/summary.component';
import { CreditmovementsComponent } from './components/creditmovements/creditmovements.component';
import { BalancecontructComponent } from './components/balancecontruct/balancecontruct.component';
import { CreditselectorComponent } from './components/creditselector/creditselector.component';
import { CredittableComponent } from './components/credittable/credittable.component';
import { PayplansComponent } from './components/payplans/payplans.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { ActuserComponent } from './components/actuser/actuser.component';
import { MovementsDetailsComponent } from './components/movements-details/movements-details.component';
import { ProblemsComponent } from './components/problems/problems.component';
import {CurrencyPipe} from '@angular/common'

import { MAT_DATE_LOCALE } from '@angular/material';
import { ReportsComponent } from './components/reports/reports.component';
import { MessageComponent } from './components/shared/message/message.component';
import { HtmlPipe } from './pipes/html.pipe';
import { CookieService } from 'ngx-cookie-service';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NavbarComponent,
    CreditDetailsComponent,
    UserrecoveryComponent,
    RegistuserComponent,
    PasswordrecoveryComponent,
    PasswordresetComponent,
    SummaryComponent,
    CreditmovementsComponent,
    BalancecontructComponent,
    CreditselectorComponent,
    CredittableComponent,
    PayplansComponent,
    WelcomeComponent,
    ActuserComponent,
    MovementsDetailsComponent,
    ProblemsComponent,
    ReportsComponent,
    MessageComponent,
    HtmlPipe
  ],
  
  imports: [

    BrowserModule, 
    BrowserAnimationsModule,
    APP_ROUTING,
    MaterialModule,
    LayoutModule,
    HttpClientModule, 
    FlexLayoutModule, 
    NgcFloatButtonModule
  ],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'es-ES' }, LoginService, CookieService , IpService, InfotransferService, CurrencyPipe ],
  bootstrap: [AppComponent],
  entryComponents:[ActuserComponent, UserrecoveryComponent, RegistuserComponent, PasswordrecoveryComponent, PasswordresetComponent, MovementsDetailsComponent, ProblemsComponent, MessageComponent]
})
export class AppModule { }
