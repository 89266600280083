import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { LoginService } from '../../services/login.service';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { ActuserComponent } from '../actuser/actuser.component';
import { ConverterService } from 'src/app/services/converter.service';

@Component({
  selector: 'app-registuser',
  templateUrl: './registuser.component.html',
  styleUrls: ['./registuser.component.css']
})
export class RegistuserComponent extends ConverterService implements OnInit {
  registFormGroup: FormGroup
  activateForm: FormGroup
  methodForm: FormGroup
  registForm: boolean

  userData: boolean
  loading: boolean
  showActivation: boolean
  canValidate: boolean
  userReg = new registUser
  passtrue: boolean = false
  registedUser = new succesUser
  errorResp: string
  page: string
  returnIni: boolean
  showError: boolean
  succesVal: boolean
  codeGen: boolean
  userInfo: any
  errorMsg = false
  serverMsg
  cell
  docType = [
    { value: '0', viewValue: 'NUIP', },
    { value: '1', viewValue: 'Cedula de Ciudadanía' },
    { value: '2', viewValue: 'Cedula de Extranjería' },
    { value: '3', viewValue: 'NIT' },
    { value: '4', viewValue: 'Tarjeta de Identidad' },
    { value: '5', viewValue: 'Pasaporte' },
    { value: '6', viewValue: 'Identificación Extranjera' },
    { value: '9', viewValue: 'Registro Civil' },
  ];

  Tooltip = `La contraseña debe ser:
 1.       De 4 números 
 2.       No debe contener números consecutivos 
 3.       No debe ser igual a la anterior. 
 4.       No debe contener letras
 5.       No debe contener caracteres especiales`
  constructor(private dialog: MatDialog,  public dialogref: MatDialogRef<RegistuserComponent>, private activateDialogRef: MatDialogRef<ActuserComponent>, private sessionInfo: LoginService,
    private _formBuilder: FormBuilder) { 
      super()
    }

  ngOnInit() {
    this.codeGen = false
    this.registForm = true
    this.userData = false
    this.loading = false
    this.showActivation = false
    this.canValidate = false
    this.returnIni = false
    this.showError = false
    this.registFormGroup = this._formBuilder.group({
      CNCDTI: ['', Validators.required],
      CNNOSS: ['', Validators.required],
      S095: ['', Validators.required],
      S096: ['', [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/),]],
      passconfirm: ['', [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
    },
      {
        validator: PasswordValidation.MatchPassword // your validation method
      });

    this.activateForm = this._formBuilder.group({
      auth_code: ['', Validators.required],
    })
    this.methodForm = this._formBuilder.group({
      method: ['', Validators.required]
    })

  }
  getErrorMessagePass() {
    return this.registFormGroup.controls.S096.hasError('required') ? 'Debe ingresar este campo' :
      this.registFormGroup.controls.S096.hasError('pattern') ? 'La contraseña solo puede contener numeros' :
        this.registFormGroup.controls.S096.hasError('minLength') ? '' : 'La contraseña debe ser de 4 numeros';
  }

  getErrorMessageConPass() {
    return this.registFormGroup.controls.passconfirm.hasError('required') ? 'Debe confirmar la contraseña' :
      this.registFormGroup.controls.passconfirm.hasError('MatchPassword') ? 'La contraseña no coincide' : '';
  }

  passcompare() {
    if (this.userReg.S096 !== this.userReg.passconfirm) {
      this.passtrue = true
    } else if (this.userReg.S096 == this.userReg.passconfirm) {
      this.passtrue = false
    }
  }

  ativateAcount() {
    let validation = {
      pin: this.activateForm.value.auth_code,
      phone: this.cell,
      applicationId: environment.appId
    }
    //this.sessionInfo.registerUser().subscribe()
    /*this.sessionInfo.verifyCode(validation).subscribe(resp => {
      // console.log(resp);
      if (resp['verified']) {
        this.registedUser.S084 = "ACTIVE"
        this.sessionInfo.activate(this.registedUser).subscribe(resp => {
          this.succesVal = true
          setTimeout(() => {
            this.dialogref.close()
          }, 6000)
        },
          (error) => {
            this.succesVal = true
            setTimeout(() => {
              this.dialogref.close()
            }, 6000)            
            // console.log(this.sessionInfo.error(error));
          })
      } else {
        this.errorMsg = true
        this.serverMsg = "Código errado";
        setTimeout(() => {
          this.errorMsg = false
        }, 4000);
      }
    },
      error => {
        this.errorMsg = true
        this.serverMsg = this.sessionInfo.error(error);
        setTimeout(() => {
          this.errorMsg = false
        }, 4000);
      })*/


  }
  activateShow() {
    //this.showActivation = true
    this.dialog.open(ActuserComponent);
  }
  wayTosend(a) {

    this.canValidate = true

    if (a.value == 2) {
      this.page = "2fa?"

    } else if (a.value == 1) {
      this.page = "2fa?type=email&"

    }
  }
  // this.registFormGroup.value.S095
  getCode(a) {
    const DATA = {
      phone: this.cell,
      applicationId: environment.appId
    }
    this.sessionInfo.sendCode(DATA).subscribe(resp => {
      //console.log(resp);
      this.codeGen = true
      setTimeout(() => {
        this.codeGen = false
      }, 3000)
    })
  }

  sendUser() {
    this.returnIni = true
    if (this.registFormGroup.valid) {
      this.registForm = false
      this.loading = true
      
      let data = {
        "CNCDTI": this.registFormGroup.value['CNCDTI'],
        "CNNOSS": this.registFormGroup.value['CNNOSS'],
        "S095": this.registFormGroup.value['S095'],
        "S096": this.registFormGroup.value['S096']
      }
      this.sessionInfo.registerUser(data).subscribe((resp) => {
        let dE = JSON.parse(this.decryptAr(resp['data']));
        this.userData = true
        this.registedUser = dE[0];
       // console.log("REGISTERed USER", resp);
        //guardar en local storage
        this.sessionInfo.setLocalStorage("US", data);

        /*if (this.registedUser.CAADD1=="NA") {
          this.hasEmail=false
        }else{
          this.hasEmail=true
        }*/

        this.loading = false
      },
        (error) => {
          this.loading = false;

          try {
            let dE = JSON.parse(this.decryptAr(error.error['data']));
            let errorE = dE.message;
            if(errorE == "INVALID_PASSWORD_FORMAT"){
              this.errorResp = "La contraseña que has ingresado no cumple las políticas"
            }else if(errorE == "USER_ALREADY_ACTIVE"){
              this.serverMsg = "El usuario ingresado ya se encuentra activo"
            }else if(errorE == "USER_NOT_FOUND"){
              this.serverMsg = "El usuario ingresado no existe en nuestra base de datos"
            }
            else if(errorE == "USERNAME_TAKEN"){
              this.errorResp = "El nombre de usuario que has ingresado ya existe o es incorrecto"
            }
            else if(errorE == "USER_ALREADY_REGISTERED"){
              this.errorResp = "Este usuario ya está registrado"
            }else{
              this.errorResp = "No se ha podido crear el usuario. Lo sentimos"
            }
          } catch(e) {
            console.warn('no se pudo descifrar el mensaje de error, usando valor de fallback', e);
            this.errorResp = "No se ha podido crear el usuario. Lo sentimos"
          }

          this.showError = true;
          setTimeout(() => {
            this.registFormGroup.reset;
            this.registForm = true
            this.userData = false
            this.showError = false
          }, 6000)
        }
      )

      //COMENTAR
      //this.userData = true;
      //this.loading = false;
    }
  }

  return() {
    this.registForm = true
    this.userData = false
    this.loading = false
    this.showError = false
  }

  generateCode(a) {
    this.sessionInfo.sendData("2fa?", a).subscribe(resp => {

    })
  }


}
export class registUser {
  CNCDTI: string;
  CNNOSS: string;
  S095: string;
  S096: string;
  passconfirm: string
}

export class succesUser {
  S100
  S098
  S003
  CNCDTI
  CAADD1
  CNNAME
  id
  CNNOSS
  S097
  CNNAMK
  S102
  S103
  S096
  CAPHC1
  S084
  S095
}
export class PasswordValidation {
  static MatchPassword(AC: AbstractControl) {
    let S096 = AC.get('S096').value; // to get value in input tag
    let passconfirm = AC.get('passconfirm').value; // to get value in input tag
    if (S096 != passconfirm) {
      AC.get('passconfirm').setErrors({ MatchPassword: true })
    } else {
      return null
    }
  }
}