import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpResponse
} from "@angular/common/http";
import { map } from "rxjs/operators";
import { Subject } from "rxjs";
import * as crypto from 'crypto-js';
import { BaseService } from "./base.service";
import { CookieService } from "ngx-cookie-service";


const sigla = "COL";
 var TOKEN: string = 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOlsiYmFua3Zpc2lvbiJdLCJ1c2VyX25hbWUiOiJDT0wiLCJzY29wZSI6WyJ0cnVzdCIsInJlYWQiLCJ3cml0ZSJdLCJleHAiOjE3NTE1NTY2OTcsImF1dGhvcml0aWVzIjpbIlJPTEVfVVNFUiJdLCJqdGkiOiIyMTU5OTlmNi1iYWY1LTRlYjctOTA3Ni1kYzFmODQwMDNhNTQiLCJjbGllbnRfaWQiOiJiYW5rdmlzaW9uIn0.ytvM1uNc_XCgih3RAs6SDrOLPoo1kKXEsU9wYnT3rp00qYgf2k-B0P87_Oea4a4miSSTVB_Br3xAV1ih3298FALkcYjXaTunfK3MqI1z__h7nzi4Cur266VIqCxcOrA_pc2Z_Fx_i3zdIP_RmpXqko0iqF2qL0AUV96Ru8UT2lEXONmkmkF4jvt9gbU-7tBpUep7f2hHEYWvldEmALffzbnFXojTouObI0CofY5u6W-A6UPSuTznwcl0QUv6pCsJKOqHp3i0KCiQ4Wq_BzN5BXzyPJ2RMV6Pp6MFhf-uPQhhg5yD6PrXFm_IBQl3TFo5AHF3bu9FfxeXXuciqkjofQ';
var httpOptions = {
  headers: new HttpHeaders({
    'Authorization': 'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOlsiYmFua3Zpc2lvbiJdLCJ1c2VyX25hbWUiOiJDT0wiLCJzY29wZSI6WyJ0cnVzdCIsInJlYWQiLCJ3cml0ZSJdLCJleHAiOjE3NTE1NTY2OTcsImF1dGhvcml0aWVzIjpbIlJPTEVfVVNFUiJdLCJqdGkiOiIyMTU5OTlmNi1iYWY1LTRlYjctOTA3Ni1kYzFmODQwMDNhNTQiLCJjbGllbnRfaWQiOiJiYW5rdmlzaW9uIn0.ytvM1uNc_XCgih3RAs6SDrOLPoo1kKXEsU9wYnT3rp00qYgf2k-B0P87_Oea4a4miSSTVB_Br3xAV1ih3298FALkcYjXaTunfK3MqI1z__h7nzi4Cur266VIqCxcOrA_pc2Z_Fx_i3zdIP_RmpXqko0iqF2qL0AUV96Ru8UT2lEXONmkmkF4jvt9gbU-7tBpUep7f2hHEYWvldEmALffzbnFXojTouObI0CofY5u6W-A6UPSuTznwcl0QUv6pCsJKOqHp3i0KCiQ4Wq_BzN5BXzyPJ2RMV6Pp6MFhf-uPQhhg5yD6PrXFm_IBQl3TFo5AHF3bu9FfxeXXuciqkjofQ'
  })
};

@Injectable({
  providedIn: "root"
})
export class LoginService extends BaseService{
  sessionInfo;
  token = "a5b0e482-7698-4d03-b418-b2267f099a15";
  httpurl = 'https://cloud.bankvision.com/v1/';
  URL = 'https://cloud.bankvision.com/web-login-crypto/web/';
  
  creditsData;
  planData;

  json = {
    token: "duban",
    id: "prueba"
  }

  formats = {
    CAPITAL: 1,
    CAUSED_INTEREST: 1,
    CONDONACION: 1,
    CURRENT_INTEREST: 1,
    DEBT_INTEREST: 1,
    DEBT_INTEREST_C: 1,
    INSURANCE_DEBTORS: 1,
    LBDTDU: 2,
    LBDTRC: 1,
    LBYAIN: 1,
    LBYALC: 1,
    LBYAPR: 1,
    LBYATO: 1,
    LKDSST: 0,
    LMAMCB: 1,
    LMAME4: 1,
    LMAMPO: 1,
    LMAMTP: 1,
    LMDTCD: 2,
    LMDTMT: 2,
    LMNOAC: 0,
    LMNOPD: 0,
    LMRTNM: 0,
    LPDESC: 0,
    TOTAL_CREDIT: 1,
    TOTAL_DEBT: 1,
    XFDESC: 0    
  }
  
  str = JSON.stringify(this.json);
  /*key = "90325F38F29BA5B60C2AA637DB78281C";*/
  encryptData;
  decrypData;

  constructor(private _http: HttpClient, private cks: CookieService) {
    super(_http, cks);
    
    // var encrypted = this.set('90325F38F29BA5B60C2AA637DB78281C', '{"phone": "573168346547","applicationId": "14530CEC384ADD5E82FEC170C6EF5445"}');
    // var decrypted = this.gete('90325F38F29BA5B60C2AA637DB78281C', "t4AP4lR6f0vr8hs4+iweq4j9MUDTtpBOLnk+WaDGnibyrMLV4NMjMeuSRIr3bj2rf4wKO7HtVuSWXKUu9GGLPEAWrbeAQ/TWDSJBWPqHmx42tPZakFvyNblTn/V2DtHOv0X/a3POx5GZgYNF9vrULX1Qm48UgolsnZ+5WgZ6QxV+lIV4RCKuKsIzBQg0F00tPPUjyzApcizRlt3QNMNjz+e4tGarnz1m7CNyCRImV4Ne70A+bxWLB0u4IyWfxhVCYZRy+6bisSVgQVfIl91LDj1lUNQLwhQZr+ZDSX2jQJXug6RPTUj+QUxYMHt+PGeVJzvTFbhE7VrjkNm+V7WOm0l7pOtESCloQXbTFRnnf0qvmpMbYrO48ssBa5VA7OrbL4cduYn7E5F4vMt1hI3f5YlihbVLdFPDI5WyH/bgUQP4OZqT1D6IbxFjXvIXhWB9o++sSBCBVTOuRrocouIPVuTXK0Sw38X4II+S4Zxdg8/pJmdxLjNBm2GzCalitK6Xs4q8t0lmUnEoYVoXTUiEYnzWmOPGmB77hosf0nbjalPcIaNueLERzKtJvyWxNHD0iYiMqG4duQm8btZ4pstmZyxU4WwIi2ef0Z/JNb2k7aRVEHmZQ/jruHkPRMoCS3Ki03bkiJ4dogXOnmOmbrdzLVjtxIUCeSXGyDqH74ak13xSzJHtIOwiIl9FNdlb/zlG");
    
    // console.log('Encrypted :' + encrypted);
    // console.log('Encrypted :' + decrypted);

    /*
    let fullUrl = window.location.href;
    fullUrl = fullUrl.replace(new RegExp(window.location.pathname, "g"), "");
    fullUrl = fullUrl.slice(0, fullUrl.length - 5);
    this.httpurl = `${fullUrl}:8090/v1/`;
    */
  }

  getHttpOptions() {
    if(TOKEN === null && localStorage.getItem("token"+sigla) !== null){
      TOKEN = 'Bearer ' + localStorage.getItem("token"+sigla);
      httpOptions.headers = new HttpHeaders({
        Authorization: `${TOKEN}`
      });
    }else if(TOKEN === null){
      this.setToken1(res => {}).then(() => {
        return httpOptions;
      });
    }
    return httpOptions;
  }
  async setToken1(_callback) {
    await this.getToken(sigla, result => {
      TOKEN = 'Bearer ' + result;
      httpOptions.headers = new HttpHeaders({
        Authorization: `Bearer ${result}`
      });
      TOKEN = 'Bearer ' + result;
      localStorage.setItem("token"+sigla, result);
      _callback();
    });
  }



  sendUser(username:string){
    let data= {"username":username};
    // console.log(data);
    let dataE= this.encrypt1(JSON.stringify(data));
    // console.log(dataE);
      return this._http.post(this.URL+"login/user?company=" + "COL",JSON.parse(JSON.stringify(dataE)), this.getHttpOptions());
  }

  sendPassword( id:string, password:string ){
    let data= {"id":id,"S096":password };
    let dataE= this.encrypt1(JSON.stringify(data));
    return this._http.post<any>(this.URL+"login/user/password?company=" + "COL", dataE , this.getHttpOptions());
  }

  recoveryUser(data){
    let dataE= this.encrypt1(JSON.stringify(data));
    return this._http.post<any>(this.URL+"login/recovery/v2?company=COL&type=username", dataE,this.getHttpOptions());
  }
 
  getRecoveryCode(data){ //recuperar contraseña
    let dataE= this.encrypt1(JSON.stringify(data));
    return this._http.post<any>(this.URL+"login/recovery/v2?company=COL&type=password", dataE , this.getHttpOptions());
  }
 

  resetPassword(data){
    let dataE= this.encrypt1(JSON.stringify(data));
    return this._http.post<any>(this.URL+"login/password/verify?company=COL", dataE , this.getHttpOptions());
  }

  registerUser(data){
    let dataE= this.encrypt1(JSON.stringify(data));
    return this._http.post<any>(this.URL+"register/v2?company=COL", dataE, this.getHttpOptions());
  }

  getActivationCode(data){
    let dataE= this.encrypt1(JSON.stringify(data));
    return this._http.post<any>(this.URL+"login/activate/search?company=COL", dataE, this.getHttpOptions());
  }

  verifyCode(data){
    let dataE= this.encrypt1(JSON.stringify(data));
    return this._http.post<any>(this.URL+"login/activate/verify?company=COL", dataE, this.getHttpOptions());
  }

  // activateVerify(data){
  //   return this.post(this.URL+'web/login/activate/verify?company=' + "COL", data, this.setOptions(0,false,false));
  // }

  //*********************** */
  register(data) {
    return this.post(this.URL+'web/register', data);
  }

  sendCode(data) {
    return this.post('sms/2fa', data);
  }

  resendCode(data) {
    return this.post('sms/2fa/resend', data);
  }

  

  activate(data) {
    return this.post(this.URL+'web/users', data);
  }

  password(data) {
    return this.post(this.URL+'web/login/password', data);
  }

  getActive(data) {
    return this.post(this.URL+'web/search', data, this.setOptions(0,false,false));
  }

  /*recoveryUser(data) {
    return this.post(this.URL+'web/login/recovery?type=username', data);
  }*/

  recoveryPassword(data) {
    return this.post(this.URL+'web/login/recovery?type=password', data);
  }

  summary(data) {
    return this.post('credit-service/credits/porpuse', data, this.setOptions(0,true,true));
  }

  bills(data) {
    return this.post('credit-service/credits/bills', data, this.setOptions(0,true,true));
  }  

  getReport (data) {
    return this.post('statement-service/statements', data, this.setOptions(0,true,true));
  }

  credits(data) {
    return this.post('credit-service/credits', data, this.setOptions(0,true,true));
  }  

  transactions(from, to, data) {
    return this.post(`credit-service/credits/transactions?from=${from}&to=${to}`, data, this.setOptions(0,true,true));
  }

  transactionsDescription(from, to, data) {
    return this.post(`credit-service/credits/transactions?from=${from}&to=${to}&type=description`, data, this.setOptions(0,true,true));
  }

  sendData(page: string, a) {
    return this._http
      .post(this.httpurl + page + "access_token=" + this.token, a)
      .pipe(
        map((data: any) => {
          sessionStorage.setItem("userInfo", JSON.stringify(data));
          this.sessionInfo = JSON.parse(sessionStorage.userInfo);
          // console.log(data);
          return this.sessionInfo;
        })
      );
  }

  getSumary() {
    this.sessionInfo = JSON.parse(sessionStorage.userInfo);
    let headers = new HttpHeaders({
      Autorizathion: "Bearer " + this.token
    });

    return this._http
      .get(
        this.httpurl +
          "clients/" +
          this.sessionInfo.key_name +
          "/credits" +
          "?access_token=" +
          this.token
      )
      .pipe(
        map(data => {
          this.creditsData = data;
          // console.log(data);
          return data;
        })
      );
  }

  registUser(page: string, a) {
    return this._http
      .post(this.httpurl + page + "?access_token=" + this.token, a)
      .pipe(
        map((data: any) => {
          sessionStorage.setItem("activateUser", JSON.stringify(data));

          return data;
        })
      );
  }
  
  /*getCode(page: string, a) {
    return this._http
      .post(this.httpurl + page + "access_token=" + this.token, a)
      .pipe(
        map((data: any) => {
          return data;
        })
      );
  }*/

  validateUser(page: string, a) {
    return this._http
      .post(this.httpurl + page + "access_token=" + this.token, a)
      .pipe(
        map((data: any) => {
          return data;
        })
      );
  }

  activateUser(a) {
    return this._http
      .post(this.httpurl + "users?" + "access_token=" + this.token, a)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getCreditdates(a, b, c, d) {
    return this._http
      .get(
        this.httpurl +
          "clients/" +
          a +
          "/credits/" +
          b +
          "/transactions?from=" +
          c +
          "&to=" +
          d +
          "&access_token=" +
          this.token
      )
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getActivateInfo(a) {
    return this._http
      .post(this.httpurl + "users/search?access_token=" + this.token, a)
      .pipe(
        map(data => {
          return data;
        })
      );
  }
}
