import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MatDialog, MatDialogRef } from "@angular/material";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  trigger,
  state,
  style,
  transition,
  animate,
  query,
  group
} from "@angular/animations";
import { LoginService } from "../../services/login.service";
import { UserrecoveryComponent } from "../userrecovery/userrecovery.component";
import { RegistuserComponent } from "../registuser/registuser.component";
import { PasswordrecoveryComponent } from "../passwordrecovery/passwordrecovery.component";
import { ActuserComponent } from "../actuser/actuser.component";
import { ProblemsComponent } from "../problems/problems.component";
import { BaseService } from "src/app/services/base.service";
import { CookieService } from "ngx-cookie-service";
import { HttpClient } from "@angular/common/http";
import { ConverterService } from "src/app/services/converter.service";
declare var $ :any
declare var require: any

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
  animations: [
    trigger("slidein", [
      state("in", style({ opacity: 0, transform: "translateX(0)" })),
      transition("void => *", [
        style({ opacity: 1, transform: "translateX(100%)" }),
        animate(500)
      ])
    ]),
    trigger("fadeIn", [
      state("in", style({ opacity: 1 })),
      transition("void=> *", [style({ opacity: 0 }), animate(1000)])
    ])
  ]
})
export class LoginComponent extends ConverterService implements OnInit {
  userForm: FormGroup;
  user = new UserData();
  showNumber: boolean = true;
  showDots: boolean = false;
  ChangeVar;

  S096 = "";
  loading;
  overColor = {
    user: "black",
    password: "black",
    validate: "black",
    problems: "black"
  };
  userrecovery: MatDialogRef<UserrecoveryComponent>;
  createuser: MatDialogRef<RegistuserComponent>;
  changepass: MatDialogRef<PasswordrecoveryComponent>;
  activate: MatDialogRef<ActuserComponent>;
  problems: MatDialogRef<ProblemsComponent>;
  errorDisp: boolean = false;
  errroMsg = "";
  
  tiles = [
    { text: "1", cols: 1, rows: 1, color: "#afafaf", mask: "*" },
    { text: "2", cols: 1, rows: 1, color: "#ff000069", mask: "*" },
    { text: "3", cols: 1, rows: 1, color: "#f17f5cde", mask: "*" },
    { text: "4", cols: 1, rows: 1, color: "#4d4d4dc4", mask: "*" },
    { text: "5", cols: 1, rows: 1, color: "#afafaf", mask: "*" },
    { text: "6", cols: 1, rows: 1, color: "#ff000069", mask: "*" },
    { text: "7", cols: 1, rows: 1, color: "#f17f5cde", mask: "*" },
    { text: "8", cols: 1, rows: 1, color: "#4d4d4dc4", mask: "*" },
    { text: "9", cols: 1, rows: 1, color: "#afafaf", mask: "*" },
    { text: "0", cols: 1, rows: 1, color: "#ff000069", mask: "*" }
  ];

  public logo = require("../../../assets/img/gestionbeca.png");
  public background = require("../../../assets/img/COLFUTURO.jpg");

  constructor(
    private routes: Router,
    private dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private logIn: LoginService,
    private _http: HttpClient, private cks: CookieService
  ) {
    super();
    logIn.deleteCookie("1524");
    logIn.deleteCookie("9163");
    sessionStorage.clear();
  }

  ngOnInit() {
    //console.log($('#S096'));
    
    $('#S096').removeClass('mat-form-field-disabled')
    this.userForm = this._formBuilder.group({
      user: ["", Validators.required],
      S096: ["", Validators.required]
    });

    this.ChangeVar = this.tiles;
    this.shuffle(this.tiles);
  }

  shuffle(a) {
    var j, x, i;
    for (i = a.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1));
      x = a[i];
      a[i] = a[j];
      a[j] = x;
    }
  }

  seeNumber(a) {
    this.showNumber = true;
    this.showDots = false;
  }
  seeDots() {
    this.showNumber = false;
    this.showDots = true;
  }
  setPassword(a) {
    if (this.S096.length < 4) {
      let pas = a;
      this.S096 = this.S096 + pas;
      this.user.S096 = this.S096;
      if (this.S096.length > 4) {
        this.S096 = this.S096.slice(0, 4);
      }
      this.user.S096 = this.S096;
    }
  }
  colorIn(a) {
    switch (a) {
      case 1:
        this.overColor.user = "red";
        break;
      case 2:
        this.overColor.password = "red";
        break;
      case 3:
        this.overColor.validate = "red";
        break;
      case 4:
        this.overColor.problems = "red";
        break;
    }
  }
  colorOut(a) {
    switch (a) {
      case 1:
        this.overColor.user = "black";
        break;
      case 2:
        this.overColor.password = "black";
        break;
      case 3:
        this.overColor.validate = "black";
        break;
      case 4:
        this.overColor.problems = "black";
        break;
    }
  }
  clearPassword() {
    this.S096 = "";
    this.user.S096 = "";
  }

  recoverUser() {
    this.userrecovery = this.dialog.open(UserrecoveryComponent, {
      width: "500px"
    });
  }
  singUp() {
    this.createuser = this.dialog.open(RegistuserComponent);
  }

  activateUser() {
    this.activate = this.dialog.open(ActuserComponent);
  }
  changePas() {
    this.changepass = this.dialog.open(PasswordrecoveryComponent, {
      width: "500px"
    });
    this.changepass.componentInstance.ChangeRecover = "Recuperar Contraseña";
  }

  log() {
    this.loading = true;
    // console.log(this.user);
    this.logIn.sendUser(this.user.S095).subscribe(resp => {
      // console.log(resp);
      let datosDesencriptados = JSON.parse(this.decryptAr(resp['data']))
      // console.log(datosDesencriptados['userLogin']);
      let id = datosDesencriptados['userLogin'].id
      this.logIn.sendPassword( id, this.user.S096 ).subscribe(resp2 => {
       
        let dE = JSON.parse(this.decryptAr(resp2['data']));
        // console.log(dE);
        this.loading = false;
        this.logIn.setCookie('' , dE);
        if(dE['user']['S084'] === "ACTIVE"){
          this.routes.navigate(["/details"]);
        }
      }, error => {
        this.loading = false;
        this.showError(error);
      })
    }, error => {
      this.loading = false;
      this.showError(error);
    });
    /*this.logIn.login(this.user).subscribe(
      data => {
        //console.log(data);
        data[0]['CAPHC1'] = data[0]['CAPHC1'][0] == '+' ? data[0]['CAPHC1'].substr(1) : data[0]['CAPHC1'];
        sessionStorage.setItem("userInfo", JSON.stringify(data[0]));
        this.logIn.sessionInfo = JSON.parse(sessionStorage.userInfo);
        if (this.logIn.sessionInfo['S084'] == "ACTIVE") {
          this.routes.navigate(["/details"]);
          //console.log("puede entrar");
        }
      },
      error => {
        //console.log(error)
        this.loading = false;
        this.errroMsg = this.logIn.error(error);
        //console.log(this.errroMsg)
        this.user.S095 = "";
        this.S096 = "";
        this.user.S096 = "";
        this.errorDisp = true;
        setTimeout(() => {
          this.errorDisp = false;
        }, 3000);
      }
    );*/
  }

  showError(error) {
    this.loading = false;
 
    this.errroMsg = error.message;
    if (this.errroMsg == "ACCOUNT_BLOCKED") {
      this.errroMsg = "Usuario bloqueado. Intenta de nuevo más tarde"
    }
    else if (this.errroMsg == "USER_DISABLED") {
      this.errroMsg = "Usuario deshabilitado. Lo sentimos"
    }
    else {
      this.errroMsg = "Nombre de usuario o contraseña incorrectos"
    }
 
    this.errorDisp = true;
    setTimeout(() => {
      this.errorDisp = false;
    }, 3000);
 
  }

  problemsPop() {
    this.problems = this.dialog.open(ProblemsComponent, {
      width: "500px",
      panelClass:"test"
    });
  }
}

export class UserData {
  S095: string;
  S096: string;
}
