import { Component, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate, query, group } from '@angular/animations';
import { LoginService } from '../../services/login.service';


@Component({
  selector: 'app-payplans',
  templateUrl: './payplans.component.html',
  styleUrls: ['./payplans.component.css'],
  animations: [
    trigger('slidein', [
      state('in', style({ opacity: 0, transform: 'translateX(0)' })),
      transition('void => *', [
        style({ opacity: 1, transform: 'translateX(100%)', }),
        animate(500)
      ])
    ]),
    trigger('fadeIn', [state('in', style({ opacity: 1 })),
    transition('void=> *', [style({ opacity: 0 }),
    animate(1000)])])
  ]
})
export class PayplansComponent implements OnInit {
  credits = []
  creditTableInfo
  showTable: boolean
  dataSource=[]
  displayInfo = ['LMNOAC', 'LBDTDU', 'LBYAPR',  'LBYAIN', 'LBYALC','LBYATO']
  displayedColumns = ['Numero del crédito', 'Fecha Limite de Pago', 'Capital', 'Interes Corriente', 'Interes de Mora', 'Total Deuda'];
  
  constructor(private _userInfo: LoginService) { }


  ngOnInit() {
    this.showTable = false
    if (typeof this._userInfo.creditsData != "undefined") {
      if (this._userInfo.creditsData.length > 0) {
        this.credits=this._userInfo.creditsData
      } 
    }
  }


  selectCredit() {
    this.dataSource=[]
    this.showTable = true
    this.dataSource = this._userInfo.planData[this.creditTableInfo];
  }
}
