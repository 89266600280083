import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { InfotransferService } from '../../services/infotransfer.service';
import { trigger, state, style, transition, animate, query, group } from '@angular/animations';


@Component({
  selector: 'app-credittable',
  templateUrl: './credittable.component.html',
  styleUrls: ['./credittable.component.css'],
  animations: [
    trigger('fadeOut', [state('in', style({ opacity: 1 })),
    transition('*=> void', [style({ opacity: 0,  }),
    animate(1000)])]),
    trigger('fadeIn', [state('in', style({ opacity: 1 })),
    transition('void=> *', [style({ opacity: 0 }),
    animate(1000)])])
  ]
})
export class CredittableComponent implements OnInit {

  constructor(private _detailedInfo: LoginService, private _compcom: InfotransferService) { }
  showTable
  creditdetails
  dataSource = []
  displayInfo = ['LMNOAC', 'XFDESC', 'LPDESC', 'LKDSST', 'LMRTNM', 'CONDONACION', 'LMDTCD', 'LMDTMT', 'LMAMCB', 'LMNOPD', 'LMAMPO']
  displayedColumns = ['Número de Crédito', 'Tipo de Crédito', 'Nombre de Producto', 'Estado', 'Tasa de Interés', 'Condonación', 'Inicio de Pagos', 'Fin de Pagos', 'Saldo Capital', 'Dias de Mora', 'Saldo Total'];
  ngOnInit() {



    this._compcom.show.subscribe(resp => {
      this.showTable = false
      this.dataSource = []
      //console.log(resp)
      this.dataSource.push(resp)
      this.creditdetails = resp
      this.showTable = true
    })
  }



}
