import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDatepicker, MatDateFormats, NativeDateAdapter, MatTableDataSource } from '@angular/material';
import { trigger, state, style, transition, animate, query, group } from '@angular/animations';
import { LoginService } from '../../services/login.service';
import { Router } from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { MovementsDetailsComponent } from '../movements-details/movements-details.component';
import {CurrencyPipe} from '@angular/common'
import { isUndefined } from 'util';


@Component({
  selector: 'app-creditmovements',
  templateUrl: './creditmovements.component.html',
  styleUrls: ['./creditmovements.component.css'],
  animations: [
    trigger('slidein', [
      state('in', style({ opacity: 0, transform: 'translateY(0)' })),
      transition('void => *', [
        style({ opacity: 1, transform: 'translateY(100%)', }),
        animate(500)
      ])
    ]),
    trigger('fadeIn', [state('in', style({ opacity: 1 })),
    transition('void=> *', [style({ opacity: 0 }),
    animate(1000)])])
  ]
})
export class CreditmovementsComponent implements OnInit {
  @ViewChild('picker1') date1: MatDatepicker<Date>
  
  dataSource
  showTable: boolean
  loading: boolean
  credits = []
  dateFormGroup: FormGroup
  displayInfo = ['LHNOAC', 'LHNOCN', 'LHDTEF', 'LFDSTC', 'LHAMTR', 'details']
  displayedColumns = ['Número de Crédito', 'Consecutivo', 'Fecha', 'Desc', 'Monto', 'Detalles']
  userdata //= JSON.parse(sessionStorage.userInfo)
  creditinfo = []
  noInfo:boolean
  creditDetails = {}
  constructor(private _formbuild: FormBuilder, private currency: CurrencyPipe ,private _dataUser: LoginService, private router: Router, public dialog: MatDialog) {
    /*if (sessionStorage.length == 0) {
      this.router.navigate(['/login'])
    } else {
      this.userdata == JSON.parse(sessionStorage.userInfo)
    }*/
    if (isUndefined(this._dataUser.readCookie("1524"))) {
      this.router.navigate(['/login'])
    } else {
      this.userdata = this._dataUser.readCookie("1524")['user']
    }
  }
  format(date: Date) {

    let day
    let month = date.getMonth() + 1
    let realmonth
    if (date.getDate() < 10) {
      day = "0" + date.getDate().toString();
    } else {
      day = date.getDate().toString();
    }
    if (month < 10) {
      realmonth = '0' + month
    } else {
      realmonth = month
    }
    let year = date.getFullYear();
    return `${year}${realmonth}${day}`;
  }

  ngOnInit() {
    this.dataSource= new MatTableDataSource()
    
  this.noInfo=false
    /*if (sessionStorage.length == 0) {
      this.router.navigate(['/login'])
    }*/
    // console.log(this._dataUser.creditsData)
    if (typeof this._dataUser.creditsData != "undefined") {
      if (this._dataUser.creditsData.length > 0) {
        this.credits = this._dataUser.creditsData
        //console.log(this.credits);
    }

    this._dataUser.credits({S075: this.userdata['CNNAMK']}).subscribe(data => {
      console.log(data)
      this.credits = data;
    })
  }

    this.loading = false
    this.showTable = false

    this.date1.startView = 'year'
    this.dateFormGroup = this._formbuild.group({
      from: ['', Validators.required],
      to: ['', Validators.required],
      credit: ['', Validators.required]
    })


  }

  sendDate() {
    this.loading = true
    this.showTable = false
    let credit = this.dateFormGroup.controls.credit.value
    let from = this.format(this.dateFormGroup.controls.from.value)
    let to = this.format(this.dateFormGroup.controls.to.value)
    const DATA = {S020:credit}
    //console.log(this._dataUser.encrypt(JSON.stringify({S020:credit})))
    this._dataUser.transactions(from, to, DATA).subscribe(data => {
      //console.log(data);
      this.dataSource.data=[]
      this.loading = false
      if (data.length<1) {
        this.noInfo=true
        setTimeout(() => {
          this.noInfo=false
        }, 5000);
      } else {
        this.showTable = true
        if (data.length > 0) {
          // let credits = []
          // this.creditDetails = {}          
          for(let dat of data) {
            /*
            if(dat['TABLE'] == 0){
              credits.push(dat)
              this.creditDetails[dat['LHNOCN']] = [];
            } else {
              this.creditDetails[dat['LHNOCN']].push(dat);
            }
            */
            dat['LHDTEF'] = this.formatDate(dat['LHDTEF']);
            dat['LHAMTR'] = this.formatCurrency(dat['LHAMTR']);
          }
          this.dataSource.data = data
/*
          credits.push(data[0])
          let position = 0;
          let consecutive = data[0]['LHNOCN']  
          this.creditDetails[consecutive] = []        
          for(let credit of data){
            if(consecutive != credit['LHNOCN']){              
              credits.push(credit)
              credits[position]['details'] = false 
              consecutive = credit['LHNOCN']
              this.creditDetails[consecutive] = []
              position++
            }else{
              if(position > 0){    
                credits[position]['details'] = true            
                this.creditDetails[consecutive].push(credit);
              }
            }
          }
          this.dataSource.data = credits
          console.log(this.creditDetails);
*/          
        } else {
          this.dataSource.data.push(data)
        }
      }      
    }, error => {
      //console.log(this._dataUser.error(error));
    })
    /*
    this._dataUser.getCreditdates(this.userdata.key_name, credit, from, to).subscribe((resp: any) => {
      this.dataSource.data=[]
      console.log(resp)
      this.loading = false
      if (resp.length<1) {
this.noInfo=true
setTimeout(() => {
  this.noInfo=false
}, 5000);
      } else {
        this.showTable = true
        if (resp.length > 1) {
          let credits = []
          this.creditDetails = {}
          credits.push(resp[0])
          let position = 0;
          let consecutive = resp[0]['consecutive_number']  
          this.creditDetails[consecutive] = []        
          for(let credit of resp){
            if(consecutive != credit['consecutive_number']){              
              credits.push(credit)
              credits[position]['details'] = false 
              consecutive = credit['consecutive_number']
              this.creditDetails[consecutive] = []
              position++
            }else{
              if(position > 0){    
                credits[position]['details'] = true            
                this.creditDetails[consecutive].push(credit);
              }
            }
          }
          this.dataSource.data = credits
          console.log(this.creditDetails);
          
        } else {
          this.dataSource.data.push(resp)
        }
      }
    })
*/

  }

  formatDate(date: any): string{
    let year = date.toString().slice(0,4);
    let month = date.toString().slice(4,6);
    let day = date.toString().slice(6,8);
    return day + "/" + month + "/" + year;
  }

  formatCurrency(value) {
    return this.currency.transform(value, 'USD', '$', '1.2-2');
  }

  openDialog(datos): void {
    const dialogRef = this.dialog.open(MovementsDetailsComponent, {
      data: datos,
      panelClass:"dialogColor"
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
    });
  }

  showMovementDetail(consecutive){
    let credit = this.dateFormGroup.controls.credit.value
    let from = this.format(this.dateFormGroup.controls.from.value)
    let to = this.format(this.dateFormGroup.controls.to.value)
    const DATA = {S020:credit, CONSECUTIVO: consecutive['LHNOCN']}
    this.loading = true;
    this.showTable = false;
    this._dataUser.transactionsDescription(from, to, DATA).subscribe(data => {
      this.loading = false;
      this.showTable = true;
      for(let dat of data) {
        dat['LHDTEF'] = this.formatDate(dat['LHDTEF']);
        dat['LHAMTR'] = this.formatCurrency(dat['LHAMTR']);
      }      
      this.openDialog(data);
    });  
    
  }
}
