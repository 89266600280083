import { Component, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate, query, group } from '@angular/animations';
import { Router } from '@angular/router';
import { isUndefined } from 'util';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css'],
  animations: [
    trigger('slidein', [
      state('in', style({ opacity: 0, transform: 'translateX(0)' })),
      transition('void => *', [
        style({ opacity: 1, transform: 'translateX(100%)', }),
        animate(500)
      ])
    ]),
    trigger('fadeIn', [state('in', style({ opacity: 1 })),
    transition('void=> *', [style({ opacity: 0 }),
    animate(1000)])])
  ]
})
export class WelcomeComponent implements OnInit {
  userInfo
  gender
  constructor(private _loginService: LoginService, private router: Router) {
    if (isUndefined(this._loginService.readCookie("1524"))) {
      this.router.navigate(['/login'])
    } else {
      //this.userInfo = JSON.parse(sessionStorage.userInfo)
      console.log(this._loginService.readCookie("1524"));
      
    }
    this.userInfo = this._loginService.readCookie("1524")['user']
   }

  ngOnInit() {
    // console.log(this.userInfo);
    if (this.userInfo.gender=="F") {
      this.gender=true
    } else {
      this.gender=false
    }

}
}
