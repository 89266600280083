import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { LoginService } from '../../services/login.service';
import { error } from 'protractor';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-actuser',
  templateUrl: './actuser.component.html',
  styleUrls: ['./actuser.component.css']
})
export class ActuserComponent implements OnInit {
  activateForm: FormGroup
  methodForm: FormGroup
  activateDatatForm:FormGroup
  loading1
  usernameActive
  page
  canActivate:boolean
  canValidate
  loading2
  codeGen 
  serverMsg
  errorMsg
  registedUser
  showMsg:boolean
  serverMsg1
  succesVal
  cell
  docType = [
    { value: '0', viewValue: 'NUIP', },
    { value: '1', viewValue: 'Cedula de Ciudadanía' },
    { value: '2', viewValue: 'Cedula de Extranjería' },
    { value: '3', viewValue: 'NIT' },
    { value: '4', viewValue: 'Tarjeta de Identidad' },
    { value: '5', viewValue: 'Pasaporte' },
    { value: '6', viewValue: 'Identificación Extranjera' },
    { value: '9', viewValue: 'Registro Civil' },
  ];

  constructor(private sessionInfo: LoginService,private dialog: MatDialog, public activateUsr: MatDialogRef<ActuserComponent>, private _formBuilder: FormBuilder) { }

  ngOnInit() {
    this.loading1=false
    this.canActivate=false
    this.errorMsg=false
    this.activateDatatForm = this._formBuilder.group({
      CNCDTI: ['', Validators.required],
      CNNOSS: ['', Validators.required],
      CNNAMK: ['', Validators.required]
     })
    
    this.activateForm = this._formBuilder.group({
      auth_code: ['', Validators.required],
    })
    this.methodForm = this._formBuilder.group({
      method: ['', Validators.required]
    })
  }

//   getUserInfo(){
//     this.loading1=true
//     console.log(this.activateDatatForm.value);
//     this.sessionInfo.getActivationCode(this.activateDatatForm.value).subscribe(resp => {
//       this.loading1=false
//       console.log(resp);
//       this.canActivate = true;
//     }, error=>{
//       this.loading1=false
//       this.showMsg=true
//       // console.error(error.error);
//       this.serverMsg1=error.error.message
//       setTimeout(() => {
//         this.showMsg=false
//       }, 4000);
//     });
//     /*this.sessionInfo.getActive(this.activateDatatForm.value).subscribe(resp=>{
//       console.log(resp)
//      this. registedUser=resp[0]
//      this.loading1=false
// if (this.registedUser.S084=="ACTIVE") {
//   this.showMsg=true
//   this.serverMsg1="Este usuario se encuentra activo"
//   setTimeout(() => {
//     this.showMsg=true
//   }, 4000);
// } else {
//   this.canActivate=true;
//   this.cell = resp[0]['CAPHC1'][0] == '+' ? resp[0]['CAPHC1'].substr(1) : resp[0]['CAPHC1'];
// }

      
      
//       // console.log(resp)
//     },
//     error=>{
//       this.loading1=false
//       this.showMsg=true
//       // console.error(error.error);
//       this.serverMsg1=error.error.message
//       setTimeout(() => {
//         this.showMsg=false
//       }, 4000);
//     }
 
  
  
//   )*/
//   }

  wayTosend(a) {

    this.canValidate = true

    if (a.value == 2) {
      this.page = "2fa?"

    } else if (a.value == 1) {
      this.page = "2fa?type=email&"

    }
  }
  
  // getCode(a) {
  //   const DATA = {
  //     phone: this.cell,
  //     applicationId: environment.appId      
  //   }
  //   // console.log(DATA);
  //   this.sessionInfo.sendCode(DATA).subscribe(resp => {
  //     // console.log(resp);
  //     this.codeGen = true
  //     setTimeout(() => {
  //       this.codeGen = false
  //     }, 3000)     
  //   }, error => {
  //     this.serverMsg=error.error.message;
  //     this.errorMsg=true
  //     setTimeout(() => {
  //       this.errorMsg = false
  //     }, 3000)      
  //   })
  //   /*
  //   this.sessionInfo.getCode(this.page, { username: this.registedUser.username }).subscribe(resp => {
  //     this.codeGen = true
  //     setTimeout(() => {
  //       this.codeGen = false
  //     }, 3000)

  //   }, 
  // (error)=>{
  //   console.log(error.error);
  //   this.serverMsg=error.error.message
  //   this.errorMsg=true
  //   setTimeout(() => {
  //     this.errorMsg = false
  //   }, 3000)
  // })
  // */
  // }
  sendCode() {
    this.loading1 = true
    let data = {
      "CNCDTI": this.activateDatatForm.value['CNCDTI'],
      "CNNOSS": this.activateDatatForm.value['CNNOSS'],
      "CNNAMK": this.activateDatatForm.value['CNNAMK']
    }
  //  console.log("activationSEND", data);
    this.sessionInfo.getActivationCode(data).subscribe(resp => {
    //  console.log("ACTIVATION", resp);
      this.registedUser = resp;
      this.loading1 = false;

      this.showError1("Código enviado exitosamente");
      this.canActivate = true;
    },
      error => {
       // console.log("active error", error);
        this.canActivate = false;
        let errorE = error.error.message;
        if(errorE == "USER_ALREADY_ACTIVE"){
          this.showError1("El usuario ingresado ya se encuentra activo")
        }else if(errorE == "USER_NOT_FOUND"){
          this.showError1("El usuario ingresado no existe")
        }else{
          this.showError1("Ha ocurrido un error. Verifica los datos o intenta de nuevo más tarde")
        }
      }
    )

    //BORRAR
    /*this.showError1("ESTE ES UN ERROr");
    this.canActivate = true; */
  }
  showError1(message) {
    this.loading1 = false;
    this.serverMsg1 = message;
    this.showMsg = true;
    setTimeout(() => {
      this.showMsg = false;
    }, 2500)
  }
  // ativateAcount() {
  //   let validation = {
  //     pin: this.activateForm.value.auth_code,
  //     phone: this.cell,
  //     applicationId: environment.appId   
  //   }
  //   this.loading1=true;
  //   let data = this.activateDatatForm.value;
  //   data['S098'] = this.activateDatatForm.value.CNNAMK;
  //   data.CNNAMK = this.activateForm.value.auth_code;
    
  //   console.log(data);

  //   this.sessionInfo.activateVerify(data).subscribe(resp => {
  //     this.usernameActive = resp.CNNAME
  //     this.succesVal = true
  //     this.loading1 = false;
  //     setTimeout(() => {
  //       this.activateUsr.close()
  //     }, 6000)
  //   }, error => {
  //       this.errorMsg=true
  //       this.serverMsg= "Código errado";
  //       setTimeout(() => {
  //         this.errorMsg=false
  //       }, 4000); 
  //   });

    
  //   /*this.sessionInfo.verifyCode(validation).subscribe(resp => {     
  //     this.registedUser.S084 = "ACTIVE"
  //     // console.log(this.registedUser);
  //     if(resp['verified']) {
  //       this.sessionInfo.activate(this.registedUser).subscribe(resp => {
  //         // console.log(resp);
  //         this.succesVal = true
  //         setTimeout(() => {
  //           this.activateUsr.close()
  //         }, 6000)
  //       }, 
  //       (error)=>{
  //         this.succesVal = true
  //         setTimeout(() => {
  //           this.activateUsr.close()
  //         }, 6000)
  //       })
  //     } else {
  //       this.errorMsg=true
  //       this.serverMsg= "Código errado";
  //       setTimeout(() => {
  //         this.errorMsg=false
  //       }, 4000);        
  //     }
  //   },
  //   error=>{
  //     this.errorMsg=true
  //     this.serverMsg=this.sessionInfo.error(error);
  //     setTimeout(() => {
  //       this.errorMsg=false
  //     }, 4000);
  //   })*/
  //   /*
  //   let validation = {
  //     auth_code: this.activateForm.value.auth_code,
  //     username: this.registedUser.username
  //   }
  //   console.log(validation);
    
  //   this.sessionInfo.validateUser("2fa/validate?", validation).subscribe(resp => {

  //     this.registedUser.status = "ACTIVE"
  //     console.log(this.registedUser);
      
  //     this.sessionInfo.activateUser(this.registedUser).subscribe(resp => {
  //       this.succesVal = true
  //       setTimeout(() => {
  //         //this.dialogref.close()
  //       }, 6000)
  //     },
    
    
  //   )

  //   },
  
  //   error=>{
  //     this.errorMsg=true
  //     console.log(error.error);
  //     this.serverMsg=error.error.message
  //     setTimeout(() => {
  //       this.errorMsg=false
  //     }, 4000);

  //   })
  //   */
  // }

  activateAccount() {
    this.loading2 = true;
    
    let data = {
      "CNCDTI": this.activateDatatForm.value['CNCDTI'],
      "CNNOSS": this.activateDatatForm.value['CNNOSS'],
      "CNNAMK": this.activateForm.value.auth_code, //codigo que ingreso la persona
      "S098": this.activateDatatForm.value['CNNAMK']
    };
    // data['S098'] = this.methodForm.value.method

  //  console.log("verifySEND", data);
    
    this.sessionInfo.verifyCode(data).subscribe(resp => {
    // console.log("verifyANSWER", resp);
      
      this.succesVal = true;

      this.showError2("");
    }, error => {
      this.loading2 = false;

      let msgE = error.message;
        if(msgE == "USER_NOT_FOUND"){
          this.showError1("Código errado")
        }else{
          this.showError1("Ha ocurrido un error. Intenta de nuevo más tarde")
        }
    })

    //COMENTAR
    //this.succesVal = true;
  }

  showError2(message) {
    this.loading2 = false;
    this.serverMsg = message;
    this.errorMsg = true;
    setTimeout(() => {
      this.errorMsg = false;
      //this.activateUsr.close();
    }, 3000)
  }

}