import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '../../services/login.service';
import { MatDialogRef, MatDialog } from '@angular/material';
import { PasswordresetComponent } from '../passwordreset/passwordreset.component';
import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-passwordrecovery',
  templateUrl: './passwordrecovery.component.html',
  styleUrls: ['./passwordrecovery.component.css']
})
export class PasswordrecoveryComponent implements OnInit {
  docType = [
    { value: '0', viewValue: 'NUIP', },
    { value: '1', viewValue: 'Cedula de Ciudadanía' },
    { value: '2', viewValue: 'Cedula de Extranjería' },
    { value: '3', viewValue: 'NIT' },
    { value: '4', viewValue: 'Tarjeta de Identidad' },
    { value: '5', viewValue: 'Pasaporte' },
    { value: '6', viewValue: 'Identificación Extranjera' },
    { value: '9', viewValue: 'Registro Civil' },
  ];

  passForgotFormGroup: FormGroup;
  emptyField: boolean
  loading: boolean
  methodForm: FormGroup
  activateForm: FormGroup
  canChange: boolean
  errorMessage: string
  canValidate
  page
  codeSended: boolean
  codeValidate: boolean
  codeError: boolean
  ChangeRecover: string
  cell: string;

  constructor(private routes: Router, private _formBuilder: FormBuilder, private passChange: LoginService,
    private dialog: MatDialog, public reset: MatDialogRef<PasswordresetComponent>) { }

  ngOnInit() {
    this.loading = false
    this.emptyField = false
    this.canChange = false
    this.codeSended = false
    this.codeValidate = false
    this.codeError = false
    this.passForgotFormGroup = this._formBuilder.group({
      CNCDTI: ['', Validators.required],
      CNNOSS: ['', Validators.required],
      S095: ['', Validators.required],
    });
    this.activateForm = this._formBuilder.group({
      auth_code: ['', Validators.required],
    })
    this.methodForm = this._formBuilder.group({
      method: ['', Validators.required]
    })

  }
  log() {

    this.loading = true
    if (this.passForgotFormGroup.valid) {
      sessionStorage.setItem("tempId", this.passForgotFormGroup.value.CNCDTI)
      sessionStorage.setItem("tempNum", this.passForgotFormGroup.value.CNNOSS)


      this.passChange.recoveryPassword(this.passForgotFormGroup.value).subscribe(res => {
        // console.log(res)
        this.loading = false
        if (res[0].S095 == this.passForgotFormGroup.value.S095) {
          this.canChange = true;
          this.cell = res[0]['CAPHC1'][0] == '+' ? res[0]['CAPHC1'].substr(1) : res[0]['CAPHC1'];
        }
      },
        (error) => {
          this.loading = false
          this.emptyField = true
          this.errorMessage = this.passChange.error(error);
          setTimeout(() => {
            this.emptyField = false
          }, 3000)
        }
      )
    }
  }

  wayTosend(a) {
    this.canValidate = true
    if (a.value == 2) {
      this.page = "?"
    } else if (a.value == 1) {
      this.page = "?type=email&"
    }
  }

  // getCode() {
  //   const DATA = {
  //     phone: this.cell,
  //     applicationId: environment.appId
  //   }
  //   this.loading = true
  //   console.log(this.passForgotFormGroup.value, this.methodForm.value);
  //   this.passChange.getRecoveryCode(this.passForgotFormGroup.value['CNCDTI'], this.passForgotFormGroup.value['CNNOSS'], this.passForgotFormGroup.value['S095'], this.methodForm.value.method).subscribe(resp => {
  //     console.log(resp);
  //     console.log(this.passForgotFormGroup.value);
  //     sessionStorage.setItem("1" , JSON.stringify(this.passForgotFormGroup.value) );
  //     sessionStorage.setItem("2" , JSON.stringify(this.methodForm.value.method) );
  //     this.loading = false;
  //     this.reset = this.dialog.open(PasswordresetComponent);
  //   }, error => 
  //   {
  //         this.loading = false
  //         this.emptyField = true
  //         this.errorMessage = "Error al enviar el código, verifique sus datos he intentelo nuevamente";
  //         setTimeout(() => {
  //           this.emptyField = false
  //         }, 6000) 
  //   });
  //   // console.log(DATA);
  //   /*this.passChange.sendCode(DATA).subscribe(resp => {
  //     this.codeSended = true
  //     setTimeout(() => { this.codeSended = false }, 3000)
  //   })*/
  // }

  getCode() {
    this.loading = true;

    let data = {
      "CNCDTI": this.passForgotFormGroup.value['CNCDTI'],
      "CNNOSS": this.passForgotFormGroup.value['CNNOSS'],
      "S095": this.passForgotFormGroup.value['S095'],
      "CNNAMK": this.methodForm.value.method
    }

  //  console.log("VERIFY send", data);
    this.passChange.getRecoveryCode(data).subscribe(resp => {
      this.loading = false;
     // console.log("VERIFY ANSWER", resp);

      //guardar en local storage
      this.passChange.setLocalStorage("US", data);

      this.codeSended = true;
      setTimeout(() => {
        this.codeSended = false;

        this.reset.close();
        this.reset = this.dialog.open(PasswordresetComponent);
      }, 2000)
    }, error => {
        this.loading = false;
        this.emptyField = true;

        let errorE = error.error.message;
        if(errorE == "USER_ALREADY_ACTIVE"){
          this.errorMessage ="Este usuario ya se encuentra activo";
        }else{
          this.errorMessage ="Ha ocurrido un error. Verifica los datos o intenta de nuevo más tarde"
        }

        setTimeout(() => {
          this.emptyField = false;
          this.passForgotFormGroup.reset();
          this.methodForm.reset();
        }, 2500)
      });

  }

  canChangePass() {
    this.codeValidate = true
    let validation = {
      pin: this.activateForm.value.auth_code,
      phone: this.cell,
      applicationId: environment.appId
    }

    this.passChange.verifyCode(validation).subscribe((resp: any) => {
      // console.log(resp)
      if (resp.verified) {
        setTimeout(() => {
          this.codeValidate = false
          this.reset.close()
          this.reset = this.dialog.open(PasswordresetComponent)
        }, 3000);
      } else {
        this.codeValidate = false
        this.codeError = true
        setTimeout(() => {
          this.codeError = false
        }, 3000);        
      }
    },
      error => {
        this.codeValidate = false
        this.codeError = true
        setTimeout(() => {
          this.codeError = false
        }, 3000);
      }

    )
  }


  goBack() {
    this.reset.close()
    this.dialog.closeAll()
    //console.log(this.reset)
  }

}
