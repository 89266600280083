import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs'
import {map} from 'rxjs/operators'


@Injectable()
export class IpService {
ip
  constructor(private http:HttpClient) { }

  getIp(){
    return this.http.get("https://jsonip.com/").pipe(map( res=>{
      this.ip=res
      return this.ip
    }))
  }

}