import { Component, OnInit } from '@angular/core';
import { InfotransferService } from '../../services/infotransfer.service';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-creditselector',
  templateUrl: './creditselector.component.html',
  styleUrls: ['./creditselector.component.css']
})
export class CreditselectorComponent implements OnInit {
  credits = []
  constructor(private _compocom: InfotransferService, private _userInfo: LoginService) { }
  ngOnInit() {
    if (typeof this._userInfo.creditsData != "undefined") {
      if (this._userInfo.creditsData.length > 0) {
        this.credits = this._userInfo.creditsData
      }
    }

  }

  selectCredit(a) {
    //console.log(a.value)
    this._compocom.showCreditInfo(a.value)
  }


}
