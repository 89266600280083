import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { isString } from "util";
import printjs from "print-js";
import * as html2canvas from "html2canvas";

@Component({
  selector: "app-message",
  templateUrl: "./message.component.html",
  styleUrls: ["./message.component.css"]
})
export class MessageComponent implements OnInit {
  message: string;
  showPrint = false;
  certificates;
  images = [];
  date;
  public logo = require("../../../../assets/img/gestionbeca.png");

  constructor(
    private dialogRef: MatDialogRef<MessageComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.certificates = data;
    const today = new Date();
    this.date =
      today.getDate() +
      "/" +
      (today.getMonth() + 1) +
      "/" +
      today.getFullYear();
    this.showPrint = true;
  }

  ngOnInit() {}

  print() {
    this.certificates.forEach((element, index) => {
      html2canvas(document.querySelector(`#page${index}`)).then(canvas => {
        this.images[index] = canvas.toDataURL();
        if(index == this.certificates.length - 1) {
          printjs({
            printable: this.images,
            type: "image",
            targetStyles: ["*"]
          });          
        }
      });
    });
  }

  close() {
    this.dialogRef.close();
  }
}
