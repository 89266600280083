import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { LoginService } from '../../services/login.service';
import { send } from 'q';
import { Router } from '@angular/router';

@Component({
  selector: 'app-passwordreset',
  templateUrl: './passwordreset.component.html',
  styleUrls: ['./passwordreset.component.css']
})
export class PasswordresetComponent implements OnInit {

  passWordreset: FormGroup
  password: string
  passwordconfirm: string
  send: boolean
  passEqual: boolean
  invalidPass
  succesPass
  loading
  oldPass
  completeFomr = {}
  serverMsg
  constructor(public dialogref: MatDialogRef<PasswordresetComponent>, private sessionInfo: LoginService,
    private _formBuilder: FormBuilder, private routes: Router) { }

  ngOnInit() {
    this.passWordreset = this._formBuilder.group({
      password: ['', Validators.required],
      passwordconfirm: ['', Validators.required],
      auth_code: ['', Validators.required]
    });
    this.send = true
    this.passEqual = false
    this.loading = false
    this.invalidPass = false
    this.succesPass = false
    this.oldPass = false
  }
  passOk(a) {
    if (this.password == this.passwordconfirm) {
      this.send = false
      this.passEqual = false
    } else {
      this.passEqual = true
      this.send = true
    }
  }
  // resetpass() {
  //   this.loading = true/*
  //   this.completeFomr = {
  //     S096: this.passWordreset.value.password,
  //     CNCDTI: sessionStorage.tempId,
  //     CNNOSS: sessionStorage.tempNum
  //   }*/
  //   console.log(this.passWordreset.value, );
  //   if (this.passWordreset.valid) {
  //     let userData = JSON.parse(sessionStorage.getItem("1"));
  //     sessionStorage.removeItem("1");
  //     let method = JSON.parse(sessionStorage.getItem("2"));
  //     sessionStorage.removeItem("2");
  //     this.sessionInfo.resetPassword(userData['CNCDTI'], userData['CNNOSS'], this.passWordreset.value.auth_code, this.passWordreset.value.password, method).subscribe(resp => {
  //       this.succesPass = true
  //       console.log(resp);
  //       this.loading = false
  //       setTimeout(() => {
  //         this.dialogref.close()
  //         sessionStorage.clear
  //         this.routes.navigate(["login"])
  //       }, 5000)
  //     }, error => {
  //       alert("El código de verificación ingresado es incorrecto o la contraseña no cumple con las políticas.");
  //     });
  //     /*this.sessionInfo.password(this.completeFomr).subscribe((resp) => {
  //       this.succesPass = true
  //       this.loading = false
  //       setTimeout(() => {
  //         this.dialogref.close()
  //         sessionStorage.clear
  //         this.routes.navigate(["login"])
  //       }, 5000)
  //     },

  //       (error) => {
  //         // console.log(this.sessionInfo.error(error))
  //         const ERROR = this.sessionInfo.error(error);
  //         this.loading = false
  //         if (ERROR == "PASSWORD_USED_RECENTLY") {
  //           this.oldPass = true
  //           setTimeout(() => {
  //             this.oldPass = false;
  //             this.passWordreset.reset()

  //           }, 1500)


  //         } else if (ERROR == "INVALID_PASSWORD_FORMAT") {
  //           this.invalidPass = true
  //           setTimeout(() => {
  //             this.invalidPass = false;
  //             this.passWordreset.reset()
  //           }, 1500)
  //         }


  //       })*/

  //   }

  // }
  resetpass() {
    this.loading = true;

    if (this.passWordreset.valid) {
      let userData = this.sessionInfo.readLocalStorage("US");

      let data = {
        CNCDTI: userData['CNCDTI'],
        CNNOSS: userData['CNNOSS'],
        S096: this.passWordreset.value.password,
        CNNAMK: this.passWordreset.value.auth_code,
        S098: userData['CNNAMK']
      }

     // console.log("RESET SEND", data);
      this.sessionInfo.resetPassword(data).subscribe((resp) => {
        this.succesPass = true;

        this.loading = false;
        setTimeout(() => {
          this.dialogref.close();
          localStorage.clear();
          this.routes.navigate(["login"])
        }, 3000)
      },
        (error) => {
          let errorE = error.error.message;
          if(errorE == "INVALID_PASSWORD_FORMAT"){
            this.serverMsg = "La contraseña que has ingresado no cumple las políticas"
          }else if(errorE == "USER_NOT_FOUND"){
            this.serverMsg = "Código errado"
          }else{
            this.serverMsg = "No se ha podido actualizar la contraseña. Verifica el código o intenta de nuevo más tarde"
          }

          this.loading = false
          this.invalidPass = true
          setTimeout(() => {
            this.invalidPass = false;
            this.passWordreset.reset()
          }, 2500)
        })

      //COMENTAR
      //this.succesPass = true;
      //this.serverMsg = "No se ha podido actualizar la contraseña. Verifica que cumpla las políticas o pruébalo más tarde"
    }
  }

}
