import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { NavbarComponent} from './components/navbar/navbar.component';
import { CreditDetailsComponent } from './components/credit-details/credit-details.component';
import { UserrecoveryComponent } from './components/userrecovery/userrecovery.component';
import { CreditmovementsComponent } from './components/creditmovements/creditmovements.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { ReportsComponent } from './components/reports/reports.component';

// import { Name4Component } from './';
// import { PageNotFoundComponent } from './';

const APP_ROUTES: Routes = [
    {path:'reports', component: ReportsComponent },
    { path: 'login', component: LoginComponent },
     { path: 'details', component: NavbarComponent, children:[
         {path:'creditdetails', component: CreditDetailsComponent},
         {path:'movements', component: CreditmovementsComponent },
         {path:'reports', component: ReportsComponent },
         {path:'welcome', component: WelcomeComponent },
     ] },
     {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    },

   

    // { path: 'path3', component: Name3Component },
    // { path: 'path4', component: Name4Component },
    // { path: '**', component: PageNotFoundComponent },

    //{ path: 'path/:routeParam', component: MyComponent },
    //{ path: 'staticPath', component: ... },
    //{ path: '**', component: ... },
    //{ path: 'oldPath', redirectTo: '/staticPath' },
    //{ path: ..., component: ..., data: { message: 'Custom' }
];


export const APP_ROUTING = RouterModule.forRoot(APP_ROUTES);
